import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const LogoPolarycs = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 100,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
        <svg width="100%" height="100%" viewBox="0 0 800 180" >
        <g id="Imagotipo-Polarycs-Color" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-2" transform="translate(33.000000, 25.714286)" fillRule="nonzero">
                <path d="M0,128.571429 L0,98.9044427 C9.11733274,98.9044427 17.8612138,95.2574389 24.3081381,88.7657081 C30.7550624,82.2739774 34.3768591,73.4693878 34.3768591,64.2882713 C34.3768591,55.1078581 30.7550624,46.3032046 24.3081381,39.8115377 C17.8612138,33.319807 9.11733274,29.6728031 0,29.6728031 L0,0 C16.8316022,0.153942557 32.9218992,6.99470405 44.7699343,19.033908 C56.6180325,31.073112 63.2653061,47.3366395 63.2653061,64.2857143 C63.2653061,81.2351726 56.6180325,97.4987002 44.7699343,109.537968 C32.9218992,121.577236 16.8316022,128.418005 0,128.571429 Z" id="Path" fill="#DE1900"/>
                <path d="M136.734694,128.571429 L136.734694,98.9044427 C145.852027,98.9044427 154.595908,95.2574389 161.042832,88.7657081 C167.489756,82.2739774 171.111553,73.4693878 171.111553,64.2882713 C171.111553,55.1078581 167.489756,46.3032046 161.042832,39.8115377 C154.595908,33.319807 145.852027,29.6728031 136.734694,29.6728031 L136.734694,0 C153.566296,0.153942557 169.656593,6.99470405 181.504628,19.033908 C193.352726,31.073112 200,47.3366395 200,64.2857143 C200,81.2351726 193.352726,97.4987002 181.504628,109.537968 C169.656593,121.577236 153.566296,128.418005 136.734694,128.571429 Z" id="Path" fill="#000000"/>
                <path d="M75.5102041,128.571429 L75.5102041,98.9044427 C84.6275368,98.9044427 93.3714179,95.2574389 99.8183422,88.7657081 C106.265266,82.2739774 109.887063,73.4693878 109.887063,64.2882713 C109.887063,55.1078581 106.265266,46.3032046 99.8183422,39.8115377 C93.3714179,33.319807 84.6275368,29.6728031 75.5102041,29.6728031 L75.5102041,0 C92.3418063,0.153942557 108.432103,6.99470405 120.280138,19.033908 C132.128237,31.073112 138.77551,47.3366395 138.77551,64.2857143 C138.77551,81.2351726 132.128237,97.4987002 120.280138,109.537968 C108.432103,121.577236 92.3418063,128.418005 75.5102041,128.571429 Z" id="Path" fill="#000000" transform="translate(107.142857, 64.285714) scale(-1, 1) translate(-107.142857, -64.285714) "/>
            </g>
            <path d="M280.8,88.72 C280.96,79.36 284.22,71.62 290.58,65.5 C296.94,59.38 304.92,56.32 314.52,56.32 C324.28,56.32 332.36,59.46 338.76,65.74 C345.16,72.02 348.36,79.88 348.36,89.32 C348.36,98.92 345.16,106.84 338.76,113.08 C332.36,119.32 324.28,122.44 314.52,122.44 C307.8,122.44 301.76,120.88 296.4,117.76 L296.4,153.16 L280.8,153.16 L280.8,88.72 Z M296.4,89.32 C296.4,94.68 298.08,99.04 301.44,102.4 C304.8,105.76 309.16,107.44 314.52,107.44 C319.8,107.44 324.16,105.76 327.6,102.4 C331.04,99.04 332.76,94.68 332.76,89.32 C332.76,84.12 331.04,79.82 327.6,76.42 C324.16,73.02 319.8,71.32 314.52,71.32 C309.24,71.32 304.9,73 301.5,76.36 C298.1,79.72 296.4,84.04 296.4,89.32 Z" id="Shape" fill="#000000" fillRule="nonzero"/>
            <path d="M414.2,113.08 C407.8,119.32 399.72,122.44 389.96,122.44 C380.2,122.44 372.14,119.32 365.78,113.08 C359.42,106.84 356.24,98.96 356.24,89.44 C356.24,79.84 359.42,71.92 365.78,65.68 C372.14,59.44 380.2,56.32 389.96,56.32 C399.72,56.32 407.8,59.44 414.2,65.68 C420.6,71.92 423.8,79.84 423.8,89.44 C423.8,98.88 420.6,106.76 414.2,113.08 Z M371.84,89.44 C371.84,94.64 373.52,98.94 376.88,102.34 C380.24,105.74 384.6,107.44 389.96,107.44 C395.24,107.44 399.6,105.74 403.04,102.34 C406.48,98.94 408.2,94.64 408.2,89.44 C408.2,84.08 406.48,79.72 403.04,76.36 C399.6,73 395.24,71.32 389.96,71.32 C384.6,71.32 380.24,73 376.88,76.36 C373.52,79.72 371.84,84.08 371.84,89.44 Z" id="Shape" fill="#000000" fillRule="nonzero"/>
            <polygon id="Path" fill="#000000" fillRule="nonzero" points="433.96 121 433.96 33.16 450.04 33.16 450.04 121"/>
            <path d="M513.36,121 L513,117.52 C507.8,120.32 501.88,121.72 495.24,121.72 C485.32,121.72 477.16,118.7 470.76,112.66 C464.36,106.62 461.16,98.8 461.16,89.2 C461.16,79.6 464.36,71.72 470.76,65.56 C477.16,59.4 485.32,56.32 495.24,56.32 C505.08,56.32 513.22,59.36 519.66,65.44 C526.1,71.52 529.36,79.28 529.44,88.72 L529.44,121 L513.36,121 Z M477.12,89.44 C477.12,94.64 478.84,98.94 482.28,102.34 C485.72,105.74 490.08,107.44 495.36,107.44 C500.72,107.44 505.08,105.74 508.44,102.34 C511.8,98.94 513.48,94.64 513.48,89.44 C513.48,84.08 511.8,79.72 508.44,76.36 C505.08,73 500.72,71.32 495.36,71.32 C490.08,71.32 485.72,73 482.28,76.36 C478.84,79.72 477.12,84.08 477.12,89.44 Z" id="Shape" fill="#000000" fillRule="nonzero"/>
            <path d="M542.84,88.6 C542.84,79.08 545.86,71.36 551.9,65.44 C557.94,59.52 566.04,56.96 576.2,57.76 L576.2,73.12 C571.16,72.96 567.02,74.5 563.78,77.74 C560.54,80.98 558.92,85.2 558.92,90.4 L558.92,121 L542.84,121 L542.84,88.6 Z" id="Path" fill="#000000" fillRule="nonzero"/>
            <path d="M611.68,154.6 C604.32,154.6 598.14,152.64 593.14,148.72 C588.14,144.8 585.16,139.64 584.2,133.24 L599.68,133.24 C601.6,138.76 605.6,141.52 611.68,141.52 C615.44,141.52 618.56,140.32 621.04,137.92 C623.52,135.52 624.8,132.12 624.88,127.72 L624.88,119.68 C621.04,121.52 616.8,122.44 612.16,122.44 C603.6,122.44 596.68,119.58 591.4,113.86 C586.12,108.14 583.48,101.04 583.48,92.56 L583.48,57.76 L599.44,57.76 L599.44,93.16 C599.44,97.32 600.66,100.58 603.1,102.94 C605.54,105.3 608.56,106.48 612.16,106.48 C615.84,106.48 618.9,105.3 621.34,102.94 C623.78,100.58 625,97.32 625,93.16 L625,57.76 L640.96,57.76 L640.96,117.52 L640.84,127.12 C640.76,135.36 638.02,142 632.62,147.04 C627.22,152.08 620.24,154.6 611.68,154.6 Z" id="Path" fill="#000000" fillRule="nonzero"/>
            <path d="M714.48,98.68 C712.88,105.8 709.14,111.58 703.26,116.02 C697.38,120.46 690.44,122.68 682.44,122.68 C672.68,122.68 664.6,119.56 658.2,113.32 C651.8,107.08 648.6,99.2 648.6,89.68 C648.6,80 651.8,71.98 658.2,65.62 C664.6,59.26 672.68,56.08 682.44,56.08 C690.44,56.08 697.36,58.32 703.2,62.8 C709.04,67.28 712.8,73.16 714.48,80.44 L698.28,80.44 C695.16,74.2 689.88,71.08 682.44,71.08 C677.08,71.08 672.72,72.8 669.36,76.24 C666,79.68 664.32,84.12 664.32,89.56 C664.32,94.92 666,99.28 669.36,102.64 C672.72,106 677.08,107.68 682.44,107.68 C689.8,107.68 695.08,104.68 698.28,98.68 L714.48,98.68 Z" id="Path" fill="#000000" fillRule="nonzero"/>
            <path d="M736.88,101.2 C737.04,103.12 737.82,104.76 739.22,106.12 C740.62,107.48 742.52,108.16 744.92,108.16 C747.24,108.16 748.92,107.64 749.96,106.6 C751,105.56 751.52,104.32 751.52,102.88 C751.52,101.68 751.02,100.6 750.02,99.64 C749.02,98.68 748.08,98 747.2,97.6 C746.32,97.2 744.84,96.6 742.76,95.8 C735.16,92.92 729.92,89.84 727.04,86.56 C724.16,83.28 722.76,79.4 722.84,74.92 C722.92,69.72 725,65.32 729.08,61.72 C733.16,58.12 738.4,56.32 744.8,56.32 C751.6,56.32 756.96,58.24 760.88,62.08 C764.8,65.92 766.84,70.92 767,77.08 L751.76,77.08 C751.76,75 751.14,73.34 749.9,72.1 C748.66,70.86 747,70.24 744.92,70.24 C743.08,70.24 741.54,70.74 740.3,71.74 C739.06,72.74 738.44,74.08 738.44,75.76 C738.44,77.6 739.18,79.06 740.66,80.14 C742.14,81.22 744.52,82.28 747.8,83.32 C754.36,85.4 759.22,87.98 762.38,91.06 C765.54,94.14 767.12,98.36 767.12,103.72 C767.12,108.84 765.08,113.24 761,116.92 C756.92,120.6 751.36,122.44 744.32,122.44 C737.84,122.44 732.5,120.4 728.3,116.32 C724.1,112.24 721.84,107.2 721.52,101.2 L736.88,101.2 Z" id="Path" fill="#000000" fillRule="nonzero"/>
        </g>
    </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <>
      {logo}
    </>
  );
});

LogoPolarycs.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default LogoPolarycs;
