import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme, styled} from '@mui/material/styles';
import { Grid, Container, Typography, Box, Table, TableBody, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
// redux
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLayout } from '../store/slices/indicadores';

// components
import {Logo, LogoPolarycs} from '../components/logo'
// ----------------------------------------------------------------------

export default function CalendarioNuevo() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const [ time, setTime ] = useState(0);
  const [counter, setCounter] = useState(1);

  const { loadingCalendario, calendario } = useSelector((state) => state.indicadores);

  // useEffect(() => {
  //   dispatch(getLayout());
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
      window.location.reload(false);
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const TagDespacho = styled('div')(() => ({
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ecocargaDetergente.lighter,
    paddingTop: 5, 
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight:15,
    marginRight: 15,
    display: 'inline-block'
  }));

  const TagDespachoCantidad = styled('div')(({ theme }) => ({
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ecocargaLavalozas.lighter,
    paddingTop: 5, 
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight:15,
    marginRight: -15,
    marginTop:-10,
    marginBottom:-10,
    marginLeft:5,
    color: theme.palette.ecocargaLavalozas.darken, 
    fontWeight: 'bold',
    display: 'inline-block',
    borderTopLeftRadius:0,
    borderBottomLeftRadius:0
  }));

  const BoxLayout = styled('div')(({ theme }) => ({
    margin: 'auto',
    borderRadius: '5px',
    width:'100%',
    justifyContent: 'center',
    display: 'grid',
    gridAutoRows: '100px'
  }));

  const BoxLayoutContainer = styled('div')(({ theme }) => ({
    borderRadius: '5px',

    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    padding: 20,
    display: 'grid',
    gridTemplateColumns:'20% 20% 20% 20% 20%',
    gridGap: 20,
    gridAutoFlow: 'column',
    textAlign: 'center',
    gridAutoRows: '100px'

  }));

  const TarjetaDespacho = styled('div')(({ theme }) => ({
    borderRadius: '5px',
    width:'100%',
    justifyContent: 'left',
    backgroundColor: '#ffffff',
    padding: 20
  }));

  const Despachos = styled('div')(({ theme }) => ({
    borderRadius: '5px',
    width:'100%',
    justifyContent: 'center',
  }));

  const ItemDespachos = styled('div')(({ theme }) => ({
    borderRadius: 0,
    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
    padding: 10,
    borderLeft : '8px solid #646464'
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#acd8ff",
      color: "#00315e",
      fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }));

  const CalendarioSemanal = styled('div')(({ theme }) => ({
    margin: 'auto',
    borderRadius: '5px',

    width:'100%',
    justifyContent: 'center',
    backgroundColor: '#7ed6df',
    marginRight: 10,
    padding: 20
  }));

  function getDayCL(day) {
    if (day === 1) return 'Lunes';
    if (day === 2) return 'Martes';
    if (day === 3) return 'Miércoles';
    if (day === 4) return 'Jueves';
    if (day === 5) return 'Viernes';
    if (day === 6) return 'Sábado';
    if (day === 7) return 'Domingo';
    return 'x'
  }
  
  function TarjetaDespachos(data) {

    return (
      <>
        <TarjetaDespacho>
          {/* Datos de Camión */}
          <Typography variant="h4" sx={{ mb:0, textAlign:'left' }}>
            PATENTE : {data.patente}
          </Typography>
          {/* Sector */}
          <Typography variant="h5" sx={{ mb: 2, textAlign:'left' }}>
            {data.ruta}
          </Typography>

          {/* Despachos */}
          <Despachos>
            {data.despachos.map( (i, e) => {
              return(
                <ItemDespachos>

                  {(i.items.map( q => {
                    return(
                    <Typography variant="h4" sx={{ mb: 1, textAlign:'left'  }}>
                      {q.cantidad} x {q.producto}
                    </Typography>
                    )
                  }))}

                  <Typography variant="h5" sx={{ mb: 0, textAlign:'left'  }}>
                    {i.pos}
                  </Typography>
                </ItemDespachos>
              )
            })}          
          </Despachos>
        </TarjetaDespacho>
      </>
    )

  }


  if(!loadingCalendario) {
    return (
      <>
        <Helmet>
          <title>Bodega EcoCarga</title>
        </Helmet>

        <Container style={{maxWidth:'100%'}}>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', right: 50 }}>
            <Logo />
          </Box>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', bottom: 20, right:50 }}>
            <LogoPolarycs sx={{marginLeft:2}}/>
          </Box>
          <Typography variant="h3" sx={{ mb: 2 }} mt={-13}>
            Mercado Circular / EcoCarga / Calendario Semanal / Actualizado {moment().format('HH:mm:ss')}
          </Typography>


          <BoxLayoutContainer item xs={6} sm={6} md={6} sx={{textAlign:'center'}}>

              <CalendarioSemanal>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Martes 30
                </Typography>
                {TarjetaDespachos({
                  'patente' : 'FVGS40',
                  'ruta' : 'Interior',
                  'despachos' :[
                    {
                      'pos' : 'EcoVending Quillota',
                      'items' : [{
                        'producto': 'DETERGENTE',
                        'cantidad' : '2'
                      },
                      {
                        'producto': 'LAVALOZAS',
                        'cantidad' : '1'
                      },
                      {
                        'producto': 'LAVALOZAS',
                        'cantidad' : '1'
                      },]
                    },
                    {
                      'pos' : 'EcoVending Marga Marga',
                      'items': [{
                        'producto': 'DETERGENTE',
                        'cantidad' : '6'
                      },
                      {
                        'producto': 'LAVALOZAS',
                        'cantidad' : '3'
                      },]
                    }
                  ]
                })}
                
              </CalendarioSemanal>

              <CalendarioSemanal>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Miércoles 1
                </Typography>
                {TarjetaDespachos({
                  'patente' : 'FVGS40',
                  'ruta' : 'Sector 3',
                  'despachos' :[
                    {
                      'pos' : 'EcoVending Municipalidad Providencia',
                      'items': [{
                        'producto': 'DETERGENTE',
                        'cantidad' : '6'
                      },
                      {
                        'producto': 'LAVALOZAS',
                        'cantidad' : '3'
                      },]
                    },
                  ]
                })}
              </CalendarioSemanal>

              <CalendarioSemanal>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Jueves 2
                </Typography>
                {TarjetaDespachos({
                  'patente' : 'FVGS40',
                  'ruta' : 'Sector 1',
                  'despachos' :[
                    {
                      'pos' : 'EcoVending Arauco El Bosque',
                      'items' : [{
                        'producto': 'LAVALOZAS',
                        'cantidad' : '1'
                      },
                      {
                        'producto': 'LAVALOZAS',
                        'cantidad' : '1'
                      },]
                    }
                  ]
                })}
              </CalendarioSemanal>


              <CalendarioSemanal>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Viernes 3
                </Typography>
                {TarjetaDespachos({
                  'patente' : 'FVGS40',
                  'ruta' : 'Sector 3',
                  'despachos' :[
                    {
                      'pos' : 'EcoVending TE Las Condes',
                      'items' : [{
                        'producto': 'DETERGENTE',
                        'cantidad' : '2'
                      },
                      {
                        'producto': 'LAVALOZAS',
                        'cantidad' : '1'
                      },]
                    }
                  ]
                })}
              </CalendarioSemanal>

              <CalendarioSemanal>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Lunes 6
                </Typography>
              </CalendarioSemanal>


            </BoxLayoutContainer>
          

        </Container>
      </>
    );
  }

  return (<>
  
    <Container style={{maxWidth:'100%'}}>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Logo />
          </Box>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Typography variant="h3" sx={{ mb: 2 }} mt={2}>
              Refrescando Calendario...
            </Typography>
          </Box>
    </Container>
  
  </>)
}
