import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 60,
        height:60,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg width="100%" height="100%" viewBox="0 0 800 800">
          <g id="icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="ecocarga-logo" transform="translate(-131.692008, -133.000000)" fill="#1769FF" fillRule="nonzero">
                  <g id="drop" transform="translate(348.692008, 184.000000)">
                      <path d="M240.639623,58.7633983 C228.354717,40.6627646 217.515094,24.0101817 207.398113,8.80564942 C205.95283,6.63357338 203.784906,4.46149734 201.616981,3.01344665 C192.945283,-2.77875612 181.383019,0.117345264 175.601887,8.80564942 C166.207547,24.0101817 155.367925,39.9387393 142.360377,58.7633983 C85.2716981,142.750338 0,269.454774 0,341.857309 C0,394.711159 21.6792453,442.496832 56.3660377,477.250048 C91.0528302,511.27924 138.74717,533 191.5,533 C244.25283,533 291.94717,511.27924 326.633962,476.526023 C361.320755,441.772806 383,393.987134 383,341.133283 C383,268.730749 297.728302,142.750338 240.639623,58.7633983 Z M300.618868,450.461111 C272.435849,478.698099 234.135849,495.350682 191.5,495.350682 C148.864151,495.350682 110.564151,477.974074 82.3811321,450.461111 C54.1981132,422.224122 37.5773585,383.850779 37.5773585,341.133283 C37.5773585,280.315154 119.235849,159.402921 173.433962,79.036108 C179.937736,69.6237785 185.718868,60.211449 191.5,52.2471702 C197.281132,60.211449 203.062264,69.6237785 209.566038,79.036108 C263.764151,160.126947 345.422642,280.315154 345.422642,341.133283 C345.422642,383.850779 328.079245,422.224122 300.618868,450.461111 Z" id="Shape"/>
                  </g>
                  <g id="refresh-button" transform="translate(507.598753, 506.500000) rotate(-136.000000) translate(-507.598753, -506.500000) translate(126.747392, 170.166025)">
                      <path d="M546.220704,69.4584603 C486.594231,24.0167135 415.353322,4.54747351e-13 340.198156,4.54747351e-13 C152.613256,4.54747351e-13 -9.09494702e-13,152.613256 -9.09494702e-13,340.198156 C-9.09494702e-13,500.154225 110.968051,634.681737 259.963521,670.840058 C285.699153,677.085598 293.931293,666.12603 294.827959,656.056359 C295.830063,644.802602 291.549309,634.598253 268.612805,628.909772 C139.079073,596.784118 42.7787684,479.526806 42.7787684,340.198156 C42.7787684,176.198617 176.198617,42.7787684 340.198156,42.7787684 C471.721479,42.7787684 589.181282,130.885207 625.839122,257.04336 C629.133087,268.386863 641.00063,274.90706 652.347699,271.613095 C663.691202,268.315565 670.214964,256.448022 666.917434,245.104518 C646.782894,175.806478 603.918568,113.424339 546.220704,69.4584603 Z" id="Shape"/>
                      <path d="M580.458634,204.635128 C572.102514,196.282573 558.559469,196.282573 550.21048,204.635128 C541.857925,212.987682 541.857925,226.530727 550.21048,234.883282 L630.670213,315.34658 C634.680723,319.35709 640.120756,321.610105 645.796073,321.610105 C651.471389,321.610105 656.911423,319.35709 660.921932,315.34658 L741.381666,234.883282 C749.73422,226.530727 749.73422,212.987682 741.381666,204.635128 C733.025546,196.282573 719.482501,196.282573 711.133511,204.635128 L645.796073,269.972566 L580.458634,204.635128 Z" id="Shape" transform="translate(645.796073, 259.990408) rotate(-18.000000) translate(-645.796073, -259.990408) "/>
                  </g>
              </g>
          </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
