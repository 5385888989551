import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme, styled} from '@mui/material/styles';
import { Grid, Container, Typography, Box, Table, TableBody, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
// redux
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLayout } from '../store/slices/indicadores';

// components
import {Logo, LogoPolarycs} from '../components/logo'
// ----------------------------------------------------------------------

export default function Calendario() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const [ time, setTime ] = useState(0);
  const [counter, setCounter] = useState(1);

  const { loadingCalendario, calendario } = useSelector((state) => state.indicadores);

  // useEffect(() => {
  //   dispatch(getLayout());
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
      window.location.reload(false);
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
  ) {
    return { name, calories, fat, carbs, protein };
  }
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

  const consolidadoDespachoSemana = [
    {
      id: 1,
      camion : 'JGCL83', 
      vuelta: 'Primera Vuelta', 
      vueltaColor: '#009fdf',
      dias: {
        martes: 'Eusebio Lillo, Francia',
        miercoles: 'D.L. Chicureo LAV, D.L.Chicureo DET',
        jueves: 'Fullerton Placilla, Eusebio Lillo',
        viernes: 'Viña del Mar',
        sabado: '-',
        domingo: '-',
        lunes: 'Quillota - Limache'
      }
    },
    {
      id: 2,
      camion : 'FVGS40', 
      vuelta: 'Primera Vuelta', 
      vueltaColor: '#009fdf',
      dias: {
        martes: 'Puente Alto, La Florida',
        miercoles: '-',
        jueves: 'Huertos del Chef, Mall Plaza Vespucio',
        viernes: '-',
        sabado: '-',
        domingo: '-',
        lunes: 'San Antonio, Arlegui'
      }
    },
    {
      id: 3,
      camion : 'FZDT25', 
      vuelta: 'Primera Vuelta', 
      vueltaColor: '#009fdf',
      dias: {
        martes: '-',
        miercoles: '-',
        jueves: '-',
        viernes: '-',
        sabado: '-',
        domingo: '-',
        lunes: 'Unimarc SF'
      }
    },
    {
      id: 4,
      camion : 'JGCL83', 
      vuelta: 'Segunda Vuelta', 
      vueltaColor: '#34eac9',
      dias: {
        martes: '-',
        miercoles: 'Limache',
        jueves: '-',
        viernes: 'Viña del Mar',
        sabado: '-',
        domingo: '-',
        lunes: 'Valparaíso'
      }
    },
    {
      id: 5,
      camion : 'FVGS40',
      vuelta: 'Segunda Vuelta', 
      vueltaColor: '#34eac9',
      dias: {
        martes: 'San Antonio, Arlegui',
        miercoles: '-',
        jueves: '-',
        viernes: '',
        sabado: 'Mall Plaza Oeste, Puente Alto',
        domingo: '-',
        lunes: '-'
      }
    },
    {
      id: 6,
      camion : 'FZDT25', 
      vuelta: 'Segunda Vuelta', 
      vueltaColor: '#34eac9',
      dias: {
        martes: '-',
        miercoles: '-',
        jueves: '-',
        viernes: '-',
        sabado: '-',
        domingo: '-',
        lunes: 'Limache'
      }
    }
  ]

  const despachosDiaActual = [
    {
      id:1,
      patente: 'JGCL83',
      vuelta: 'Primera Vuelta', 
      vueltaColor: '#009fdf',
      pos: 'Eusebio Lillo', 
      productos: [
        {
          producto: 'detegente',
          cantidad : 1000
        },
        {
          producto: 'suavizante',
          cantidad : 0
        },
        {
          producto: 'lavalozas',
          cantidad : 500
        },
        {
          producto: 'clorogel',
          cantidad : 0
        }
      ]
    },
    {
      id:2,
      patente: 'JGCL83',
      vuelta: 'Primera Vuelta', 
      vueltaColor: '#009fdf',
      pos: 'Francia', 
      productos: [
        {
          producto: 'detegente',
          cantidad : 0
        },
        {
          producto: 'suavizante',
          cantidad : 0
        },
        {
          producto: 'lavalozas',
          cantidad : 500
        },
        {
          producto: 'clorogel',
          cantidad : 0
        }
    ]
    },
    {
      id:3,
      patente: 'FVGS40',
      vuelta: 'Primera Vuelta', 
      vueltaColor: '#009fdf',
      pos: 'Puente Alto', 
      productos: [
        {
          producto: 'detegente',
          cantidad : 1000
        },
        {
          producto: 'suavizante',
          cantidad : 0
        },
        {
          producto: 'lavalozas',
          cantidad : 0
        },
        {
          producto: 'clorogel',
          cantidad : 0
        }
    ]
    },
    {
      id:4,
      patente: 'FVGS40',
      vuelta: 'Primera Vuelta', 
      vueltaColor: '#009fdf',
      pos: 'La Florida', 
      productos: [
        {
          producto: 'detegente',
          cantidad : 1000
        },
        {
          producto: 'suavizante',
          cantidad : 0
        },
        {
          producto: 'lavalozas',
          cantidad : 0
        },
        {
          producto: 'clorogel',
          cantidad : 0
        }
      ]
    },
    {
      id:5,
      patente: 'JGCL83',
      vuelta: 'Segunda Vuelta', 
      vueltaColor: '#34eac9',
      pos: 'San Antonio', 
      productos: [
        {
          producto: 'detegente',
          cantidad : 1000
        },
        {
          producto: 'suavizante',
          cantidad : 0
        },
        {
          producto: 'lavalozas',
          cantidad : 0
        },
        {
          producto: 'clorogel',
          cantidad : 0
        }
      ]
    },
    {
      id:6,
      patente: 'JGCL83',
      vuelta: 'Segunda Vuelta', 
      vueltaColor: '#34eac9',
      pos: 'Arlegui', 
      productos: [
        {
          producto: 'detegente',
          cantidad : 0
        },
        {
          producto: 'suavizante',
          cantidad : 500
        },
        {
          producto: 'lavalozas',
          cantidad : 0
        },
        {
          producto: 'clorogel',
          cantidad : 0
        }
      ]
    }
  ]

  const despachosDiaSiguiente = [
    {
      id: 1,
      patente: 'FVGS40',
      vuelta: 'Primera Vuelta', 
      vueltaColor: '#009fdf',
      pos: 'D.L. Chicureo LAV', 
      productos: [
        {
          producto: 'detegente',
          cantidad : 0
        },
        {
          producto: 'suavizante',
          cantidad : 0
        },
        {
          producto: 'lavalozas',
          cantidad : 500
        },
        {
          producto: 'clorogel',
          cantidad : 0
        }
      ]
    },
    {
      id: 2,
      patente: 'FVGS40',
      vuelta: 'Primera Vuelta', 
      vueltaColor: '#009fdf',
      pos: 'D.L.Chicureo DET', 
      productos: [
        {
          producto: 'detegente',
          cantidad : 1000
        },
        {
          producto: 'suavizante',
          cantidad : 0
        },
        {
          producto: 'lavalozas',
          cantidad : 0
        },
        {
          producto: 'clorogel',
          cantidad : 0
        }
    ]
    },
    {
      id: 2,
      patente: 'FVGS40',
      vuelta: 'Segunda Vuelta', 
      vueltaColor: '#34eac9',
      pos: 'Limache', 
      productos: [
          {
            producto: 'detegente',
            cantidad : 1000
          },
          {
            producto: 'suavizante',
            cantidad : 0
          },
          {
            producto: 'lavalozas',
            cantidad : 500
          },
          {
            producto: 'clorogel',
            cantidad : 0
          }
      ]
    }
  ]

  const TagDespacho = styled('div')(() => ({
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ecocargaDetergente.lighter,
    paddingTop: 5, 
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight:15,
    marginRight: 15,
    display: 'inline-block'
  }));

  const TagDespachoCantidad = styled('div')(({ theme }) => ({
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ecocargaLavalozas.lighter,
    paddingTop: 5, 
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight:15,
    marginRight: -15,
    marginTop:-10,
    marginBottom:-10,
    marginLeft:5,
    color: theme.palette.ecocargaLavalozas.darken, 
    fontWeight: 'bold',
    display: 'inline-block',
    borderTopLeftRadius:0,
    borderBottomLeftRadius:0
  }));

  const BoxLayout = styled('div')(({ theme }) => ({
    margin: 'auto',
    borderRadius: '5px',
    alignItems: 'center',
    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    backgroundColor: '#e8f4fc',
    padding: 20
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#acd8ff",
      color: "#00315e",
      fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }));

  function getDayCL(day) {
    if (day === 1) return 'Lunes';
    if (day === 2) return 'Martes';
    if (day === 3) return 'Miércoles';
    if (day === 4) return 'Jueves';
    if (day === 5) return 'Viernes';
    if (day === 6) return 'Sábado';
    if (day === 7) return 'Domingo';
    return 'x'
  }

  if(!loadingCalendario) {
    return (
      <>
        <Helmet>
          <title>Bodega EcoCarga</title>
        </Helmet>

        <Container style={{maxWidth:'100%'}}>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', right: 50 }}>
            <Logo />
          </Box>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', bottom: 20, right:50 }}>
            <LogoPolarycs sx={{marginLeft:2}}/>
          </Box>
          <Typography variant="h3" sx={{ mb: 2 }} mt={-13}>
            Mercado Circular / EcoCarga / Calendario de Despacho / Actualizado {moment().format('HH:mm:ss')}
          </Typography>

          <BoxLayout item xs={12} sm={6} md={6} sx={{textAlign:'left'}}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Semana #47 del Martes 22 al Lunes 28 
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" ariaLabel="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Camión</StyledTableCell>
                  <StyledTableCell align="left">Num. Vuelta</StyledTableCell>
                  <StyledTableCell align="center">Martes</StyledTableCell>
                  <StyledTableCell align="center">Miércoles</StyledTableCell>
                  <StyledTableCell align="center">Jueves</StyledTableCell>
                  <StyledTableCell align="center">Viernes</StyledTableCell>
                  <StyledTableCell align="center">Sábado</StyledTableCell>
                  <StyledTableCell align="center">Lunes</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {consolidadoDespachoSemana.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell align="center">{row.camion}</StyledTableCell>
                    <StyledTableCell align="left" sx={{backgroundColor: row.vueltaColor, fontWeight:'bold'}}>{row.vuelta}</StyledTableCell>
                    <StyledTableCell align="center">{row.dias.martes}</StyledTableCell>
                    <StyledTableCell align="center">{row.dias.miercoles}</StyledTableCell>
                    <StyledTableCell align="center">{row.dias.jueves}</StyledTableCell>
                    <StyledTableCell align="center">{row.dias.viernes}</StyledTableCell>
                    <StyledTableCell align="center">{row.dias.sabado}</StyledTableCell>
                    <StyledTableCell align="center">{row.dias.lunes}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </BoxLayout>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={12}>
              <BoxLayout item xs={12} sm={6} md={6} sx={{textAlign:'left'}}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Despachos de hoy {getDayCL(moment().day())} {moment().format('DD')}
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" ariaLabel="a dense table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Patente</StyledTableCell>
                        <StyledTableCell align="center">Vuelta</StyledTableCell>
                        <StyledTableCell align="center">POS</StyledTableCell>
                        <StyledTableCell align="left">Despachos</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {despachosDiaActual.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <StyledTableCell align="center">{row.patente}</StyledTableCell>
                          <StyledTableCell align="center" sx={{backgroundColor: row.vueltaColor, fontWeight:'bold'}}>{row.vuelta}</StyledTableCell>
                          <StyledTableCell align="center">{row.pos}</StyledTableCell>
                          <StyledTableCell align="left">

                            {row.productos.map((item) => (
                                <>
                                 {(item.cantidad > 0) ? (
                                  <TagDespacho>
                                    {item.producto.toUpperCase()} <TagDespachoCantidad>{item.cantidad}</TagDespachoCantidad>
                                  </TagDespacho>
                                 ):(<></>)}
                                </>
                            ))}

                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
            </Table>
          </TableContainer>
              </BoxLayout>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <BoxLayout item xs={12} sm={6} md={6} sx={{textAlign:'left'}}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Despachos de mañana {getDayCL(moment().add(1, 'days').day())} {moment().add(1, 'days').format('DD')} 
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" ariaLabel="a dense table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Patente</StyledTableCell>
                        <StyledTableCell align="center">Vuelta</StyledTableCell>
                        <StyledTableCell align="center">POS</StyledTableCell>
                        <StyledTableCell align="left">Despachos</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {despachosDiaSiguiente.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <StyledTableCell align="center">{row.patente}</StyledTableCell>
                          <StyledTableCell align="center" sx={{backgroundColor: row.vueltaColor, fontWeight:'bold'}}>{row.vuelta}</StyledTableCell>
                          <StyledTableCell align="center">{row.pos}</StyledTableCell>
                          <StyledTableCell align="left">
                            
                            {row.productos.map((item) => (
                                <>
                                 {(item.cantidad > 0) ? (
                                  <TagDespacho>
                                    {item.producto.toUpperCase()} <TagDespachoCantidad>{item.cantidad}</TagDespachoCantidad>
                                  </TagDespacho>
                                 ):(<></>)}
                                </>
                            ))}

                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
            </Table>
          </TableContainer>
              </BoxLayout>
            </Grid>  
  
          </Grid>
          

        </Container>
      </>
    );
  }

  return (<>
  
    <Container style={{maxWidth:'100%'}}>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Logo />
          </Box>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Typography variant="h3" sx={{ mb: 2 }} mt={2}>
              Refrescando Planta...
            </Typography>
          </Box>
    </Container>
  
  </>)
}
