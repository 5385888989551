// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppKeep.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
  prefix : PropTypes.string,
  showTitle: PropTypes.bool,
  marginBottom: PropTypes.number
};

export default function AppKeep({ title, total, icon, color = 'primary', sx, prefix, showTitle, marginBottom = 0, ...other }) {
  return (
    <Card
      sx={{
        py: .8,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
        // marginBottom: marginBottom
      }}
      {...other}
    >
      {(total) ? (
        <Typography variant="h6">{fShortenNumber(total).replace(',','.')}</Typography>
      ):(<Typography variant="h6">0</Typography>)}
      
      {(prefix) ? (
        <Typography variant="h6">{prefix}</Typography>
      ):(<></>)}
      
      {(showTitle) ? (
        <Typography variant="h7" sx={{ opacity: 0.72 }}>
          {title}
        </Typography>
      ):(<></>)}

    </Card>
  );
}
