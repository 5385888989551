
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

const PlantaPhilippiv2 = forwardRef(({ disabledLink = false, leyenda = '', layout = [], sx, ...other }, ref) => {

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 900,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >

<svg width="100%" height="100%" viewBox="0 0 874 900">
    <g id="PrimerPiso" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon id="planta" fill="#FFFDF1" opacity="0.573808943" transform="translate(442.221559, 416.926146) rotate(-180.000000) translate(-442.221559, -416.926146) " points="857.721559 16.5 857.721559 817.5 26.7215586 817.5 26.9431171 133.352293 338.943117 133.352293 338.943117 16.352293"/>
        <g id="Group" transform="translate(442.000000, 417.000000) rotate(-180.000000) translate(-442.000000, -417.000000) translate(26.000000, 16.000000)" stroke="#FFF1A8" strokeLinecap="square" strokeWidth="6">
            <line x1="0.5" y1="117.5" x2="312.5" y2="117.5" id="Line"/>
            <line x1="312.5" y1="117.5" x2="312.5" y2="0.5" id="Line-2"/>
            <line x1="312.5" y1="0.5" x2="832" y2="1" id="Line-3"/>
            <line x1="767.5" y1="0.5" x2="767.5" y2="45.5" id="Line-4"/>
            <line x1="831.5" y1="0.5" x2="831.5" y2="234.5" id="Line-5"/>
            <line x1="831.5" y1="312.5" x2="831.5" y2="563.5" id="Line-6"/>
            <line x1="831.5" y1="591.5" x2="831.5" y2="800.5" id="Line-7"/>
            <line x1="728.5" y1="800.5" x2="161.5" y2="800.5" id="Line-8"/>
            <line x1="58" y1="801" x2="4" y2="801" id="Line-9"/>
            <line x1="3.5" y1="800.5" x2="0.5" y2="117.5" id="Line-10"/>
            <line x1="3.5" y1="415.5" x2="56.5" y2="415.5" id="Line-11"/>
            <line x1="56.5" y1="415.5" x2="56.5" y2="498.5" id="Line-12"/>
            <line x1="56.5" y1="498.5" x2="2.5" y2="498.5" id="Line-13"/>
            <line x1="4" y1="558" x2="56.5" y2="557.5" id="Line-14"/>
            <line x1="56.5" y1="557.5" x2="56.5" y2="498.5" id="Line-15"/>
            <line x1="161.5" y1="415.5" x2="109.5" y2="415.5" id="Line-16"/>
            <line x1="109.5" y1="415.5" x2="109.5" y2="482.5" id="Line-17"/>
            <line x1="109.5" y1="498.5" x2="109.5" y2="531.5" id="Line-18"/>
            <line x1="109.5" y1="528.5" x2="161.5" y2="528.5" id="Line-19"/>
            <line x1="161.5" y1="528.5" x2="161.5" y2="122.5" id="Line-20"/>
            <line x1="161.5" y1="163.5" x2="216.5" y2="163.5" id="Line-21"/>
            <line x1="216.5" y1="163.5" x2="216.5" y2="197.5" id="Line-22"/>
            <line x1="216.5" y1="234.5" x2="216.5" y2="325.5" id="Line-23"/>
            <line x1="216.5" y1="325.5" x2="161.5" y2="325.5" id="Line-24"/>
        </g>
        <g id="zona-bodega-1" transform="translate(776.500000, 555.500000) rotate(0) translate(-776.500000, -555.500000) translate(725.000000, 530.000000)" fill="#00315E" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2">
            <text id="BODEGA-ENVASES-Y-CAD">
                <tspan x="19.4596191" y="14">BODEGA </tspan>
                <tspan x="16.9119141" y="31">ENVASES </tspan>
                <tspan x="2.41923828" y="48">Y CAD. TRAD.</tspan>
            </text>
        </g>
        <g id="zona-transito1" transform="translate(623.001487, 252.984260) rotate(-360.000000) translate(-623.001487, -252.984260) translate(549.000000, 218.484260)">
            <path d="M0,64 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L143,0 C145.761424,-5.07265313e-16 148,2.23857625 148,5 L148,64 C148,66.7614237 145.761424,69 143,69 L5,69 C2.23857625,69 3.38176876e-16,66.7614237 0,64 Z" id="zona-Transito" fill={layout ? layout.find(o => o.codigo === 'TRANSITO-1').semaforo : 0}/>
            <text id="C:-4" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="60.2342734" y="43.4842596">C: {layout ? layout.find(o => o.codigo === 'TRANSITO-1').capacidad : 0}</tspan>
            </text>
            <text id="CANT:-4" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="45.9517539" y="60.4842596">CANT: {layout ? layout.find(o => o.codigo === 'TRANSITO-1').contenedoresLlenos : 0}</tspan>
            </text>
            <text id="TRÁNSITO-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="30.552291" y="24.4842596">TRÁNSITO 1</tspan>
            </text>
        </g>
        <g id="sala-etiquetado" transform="translate(623.500000, 96.000000) rotate(-360.000000) translate(-623.500000, -96.000000) translate(572.000000, 79.000000)" fill="#00315E" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2">
            <text id="SALA-DE-ETIQUETADO">
                <tspan x="19.5596191" y="14">SALA DE </tspan>
                <tspan x="3.88916016" y="31">ETIQUETADO</tspan>
            </text>
        </g>
        <g id="sala-etiquetado" transform="translate(630.500000, 161.500000) rotate(-360.000000) translate(-630.500000, -161.500000) translate(579.000000, 153.000000)" fill="#000000" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2">
            <text id="PANTALLAS">
                <tspan x="8.09946289" y="14">PANTALLAS</tspan>
            </text>
        </g>
        <rect id="Rectangle" fill="#0C0C0C" x="568" y="142" width="129" height="9" rx="4.5"/>
        <g id="zona-despacho" transform="translate(754.000000, 96.000000) rotate(-360.000000) translate(-754.000000, -96.000000) translate(710.000000, 79.000000)" fill="#00315E" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2">
            <text id="DESPACHO-CAMIÓN">
                <tspan x="2.85053711" y="14">DESPACHO</tspan>
                <tspan x="14.1861816" y="31">CAMIÓN</tspan>
            </text>
        </g>
        <g id="zona-z1" transform="translate(126.000000, 25.000000)">
            <path d="M0,112 L0,5 C-3.38176876e-16,2.23857625 2.23857625,2.27880941e-13 5,2.27373675e-13 L412,2.27373675e-13 C414.761424,1.94982129e-13 417,2.23857625 417,5 L417,112 C417,114.761424 414.761424,117 412,117 L5,117 C2.23857625,117 3.38176876e-16,114.761424 0,112 Z" id="zona-1-1A" fill={layout ? layout.find(o => o.codigo === 'Z1').semaforo : 0}/>
            <text id="Z1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="8.44755859" y="28">Z1</tspan>
            </text>
            <text id="%-LLENOS-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="252.193896" y="29">% LLENOS : {layout ? layout.find(o => o.codigo === 'Z1').porcentajeContenedoresLlenos : 0}</tspan>
            </text>
            <text id="%-VACIOS-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="253.413135" y="52">% VACIOS : {layout ? layout.find(o => o.codigo === 'Z1').porcentajeContenedoresVacios : 0}</tspan>
            </text>
            <text id="CANTIDAD-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="251.201221" y="75">CANTIDAD : {layout ? layout.find(o => o.codigo === 'Z1').totalContenedores : 0}</tspan>
            </text>
            <text id="CAPACIDAD-:-40" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="6.44931641" y="45">CAPACIDAD : {layout ? layout.find(o => o.codigo === 'Z1').capacidad : 0}</tspan>
            </text>
        </g>
        <g id="zona-2" transform="translate(374.500000, 232.000000) rotate(-360.000000) translate(-374.500000, -232.000000) translate(207.000000, 195.000000)">
            <path d="M0,69 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L330,0 C332.761424,-5.07265313e-16 335,2.23857625 335,5 L335,69 C335,71.7614237 332.761424,74 330,74 L5,74 C2.23857625,74 3.38176876e-16,71.7614237 0,69 Z" id="zona-2-2A" fill={layout ? layout.find(o => o.codigo === 'Z2').semaforo : 0}/>
            <text id="Z2" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="6.44755859" y="21">Z2</tspan>
            </text>
            <text id="CAPACIDAD-:-22" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="4.44931641" y="38">CAPACIDAD : {layout ? layout.find(o => o.codigo === 'Z2').capacidad : 0}</tspan>
            </text>
            <text id="%-LLENOS-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="190.193896" y="22">% LLENOS : {layout ? layout.find(o => o.codigo === 'Z2').porcentajeContenedoresLlenos : 0} </tspan>
            </text>
            <text id="%-VACIOS-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="192.413135" y="41">% VACIOS : {layout ? layout.find(o => o.codigo === 'Z2').porcentajeContenedoresVacios : 0} </tspan>
            </text>
            <text id="CANTIDAD-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="190.201221" y="61">CANTIDAD : {layout ? layout.find(o => o.codigo === 'Z2').totalContenedores : 0} </tspan>
            </text>
        </g>
        <g id="zona-pesaje" transform="translate(125.000000, 195.000000)">
            <path d="M0,69 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L75,0 C77.7614237,-5.07265313e-16 80,2.23857625 80,5 L80,69 C80,71.7614237 77.7614237,74 75,74 L5,74 C2.23857625,74 3.38176876e-16,71.7614237 0,69 Z" id="zona-Balanza" fill="#D0D0D0"/>
            <text id="ZONA-DE-PESAJE" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="7.22832031" y="36">ZONA DE </tspan>
                <tspan x="11.002832" y="53">PESAJE</tspan>
            </text>
        </g>
        <g id="zona-z3" transform="translate(333.000000, 356.000000) rotate(-360.000000) translate(-333.000000, -356.000000) translate(127.000000, 319.000000)">
            <path d="M0,69 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L407,0 C409.761424,-5.07265313e-16 412,2.23857625 412,5 L412,69 C412,71.7614237 409.761424,74 407,74 L5,74 C2.23857625,74 3.38176876e-16,71.7614237 0,69 Z" id="zona-3-3A" fill={layout ? layout.find(o => o.codigo === 'Z3').semaforo : 0}/>
            <text id="Z3" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="12.4475586" y="20">Z3</tspan>
            </text>
            <text id="CAPACIDAD-:-40" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="9.94931641" y="36">CAPACIDAD : {layout ? layout.find(o => o.codigo === 'Z3').capacidad : 0}</tspan>
            </text>
            <text id="%-LLENOS-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="238.693896" y="23">% LLENOS : {layout ? layout.find(o => o.codigo === 'Z3').porcentajeContenedoresLlenos : 0}</tspan>
            </text>
            <text id="%-VACIOS-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="240.913135" y="43">% VACIOS : {layout ? layout.find(o => o.codigo === 'Z3').porcentajeContenedoresVacios : 0}</tspan>
            </text>
            <text id="CANTIDAD-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="238.701221" y="63">CANTIDAD : {layout ? layout.find(o => o.codigo === 'Z3').totalContenedores : 0}</tspan>
            </text>
        </g>

        {/* <g id="zona-z4" transform="translate(125.000000, 400.000000)">
            <path d="M0.189873418,103 L0.189873418,5 C0.189873418,2.23857625 2.42844967,5.07265313e-16 5.18987342,0 L92.1898734,0 C94.9512972,-5.07265313e-16 97.1898734,2.23857625 97.1898734,5 L97.1898734,103 C97.1898734,105.761424 94.9512972,108 92.1898734,108 L5.18987342,108 C2.42844967,108 0.189873418,105.761424 0.189873418,103 Z" id="zona-7-7A" fill={layout ? layout.find(o => o.codigo === 'Z4').semaforo : 0}/>
            <text id="Z6-:-VACÍOS" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="6.17415076" y="23">Z4 : VACÍOS</tspan>
            </text>
            <text id="CAP:-32" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="20.4414551" y="44">CAP: {layout ? layout.find(o => o.codigo === 'Z4').capacidad : 0}</tspan>
            </text>
            <text id="CANTIDAD-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="10.1374023" y="75">CANTIDAD</tspan>
                <tspan x="44.4288574" y="92">{layout ? layout.find(o => o.codigo === 'Z4').totalContenedores : 0}</tspan>
            </text>
        </g> */}


        <g id="zona-z4" transform="translate(125.000000, 400.000000)">
            <path d="M0,103 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L197,0 C199.761424,-5.07265313e-16 202,2.23857625 202,5 L202,103 C202,105.761424 199.761424,108 197,108 L5,108 C2.23857625,108 3.38176876e-16,105.761424 0,103 Z" id="zona-7-7A" fill={layout ? layout.find(o => o.codigo === 'Z4').semaforo : 0}/>
            <text id="Z6-:-VACÍOS" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="58.4842773" y="23">Z4 : VACÍOS</tspan>
            </text>
            <text id="CAPACIDAD-:-32" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="42.9493164" y="44">CAPACIDAD : {layout ? layout.find(o => o.codigo === 'Z4').capacidad : 0}</tspan>
            </text>
            <text id="CANTIDAD-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="62.6374023" y="75">CANTIDAD</tspan>
                <tspan x="96.9288574" y="92">{layout ? layout.find(o => o.codigo === 'Z4').totalContenedores : 0}</tspan>
            </text>
        </g>

        {/* <g id="zona-z5" transform="translate(320.000000, 453.348485) rotate(-360.000000) translate(-320.000000, -453.348485) translate(227.000000, 399.848485)">
            <path d="M0,102 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L181,0 C183.761424,-5.07265313e-16 186,2.23857625 186,5 L186,102 C186,104.761424 183.761424,107 181,107 L5,107 C2.23857625,107 3.38176876e-16,104.761424 0,102 Z" id="zona-4-4B" fill={layout ? layout.find(o => o.codigo === 'Z5').semaforo : 0}/>
            <text id="Z5-:-MERMAS" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="45.35" y="25.8484848">Z5 : MERMAS</tspan>
            </text>
            <text id="CAPACIDAD-:-3" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="39.020459" y="50.8484848">CAPACIDAD : {layout ? layout.find(o => o.codigo === 'Z5').capacidad : 0}</tspan>
            </text>
            <text id="CANTIDAD-:-3" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="44.2012207" y="75.8484848">CANTIDAD : {layout ? layout.find(o => o.codigo === 'Z5').totalContenedores : 0}</tspan>
            </text>
        </g> */}
        <g id="zona-z5" transform="translate(370.500000, 453.000000) rotate(-360.000000) translate(-370.500000, -453.000000) translate(334.000000, 399.000000)">
            <path d="M0,103 L0,5 C-3.38176876e-16,2.23857625 2.23857625,2.27880941e-13 5,2.27373675e-13 L68,2.27373675e-13 C70.7614237,2.2686641e-13 73,2.23857625 73,5 L73,103 C73,105.761424 70.7614237,108 68,108 L5,108 C2.23857625,108 3.38176876e-16,105.761424 0,103 Z" id="zona-4-4B" fill={layout ? layout.find(o => o.codigo === 'Z5').semaforo : 0}/>
            <text id="Z5-M1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="27.9475586" y="26">Z5</tspan>
                <tspan x="26.2812988" y="43">M1</tspan>
            </text>
            <text id="CAP-:-3" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="10.6643555" y="71">CAP : {layout ? layout.find(o => o.codigo === 'Z5').capacidad : 0}</tspan>
            </text>
            <text id="CANT-:-3" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="5.63374023" y="96">CANT : {layout ? layout.find(o => o.codigo === 'Z5').totalContenedores : 0}</tspan>
            </text>
        </g>

        
        {/* <g id="zona-z6" transform="translate(464.000000, 452.848485) rotate(-360.000000) translate(-464.000000, -452.848485) translate(416.000000, 398.848485)">
            <path d="M0,103 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L91,0 C93.7614237,-5.07265313e-16 96,2.23857625 96,5 L96,103 C96,105.761424 93.7614237,108 91,108 L5,108 C2.23857625,108 3.38176876e-16,105.761424 0,103 Z" id="zona-4-4A" fill={layout ? layout.find(o => o.codigo === 'Z6').semaforo : 0}/>
            <text id="Z6-:-VACÍOS" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2"  fill="#00315E">
                <tspan x="5.48427734" y="22.8484848">Z6 : VACÍOS</tspan>
            </text>
            <text id="CAP:-12" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="19.9414551" y="46.8484848">CAP: {layout ? layout.find(o => o.codigo === 'Z6').capacidad : 0}</tspan>
            </text>
            <text id="CANTIDAD-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="9.63740234" y="78.8484848">CANTIDAD</tspan>
                <tspan x="43.9288574" y="95.8484848">{layout ? layout.find(o => o.codigo === 'Z6').totalContenedores : 0}</tspan>
            </text>
        </g> */}
        <g id="zona-z6" transform="translate(476.500000, 453.000000) rotate(-360.000000) translate(-476.500000, -453.000000) translate(414.000000, 399.000000)">
            <path d="M0,103 L0,5 C-3.38176876e-16,2.23857625 2.23857625,2.27880941e-13 5,2.27373675e-13 L120,2.27373675e-13 C122.761424,2.2686641e-13 125,2.23857625 125,5 L125,103 C125,105.761424 122.761424,108 120,108 L5,108 C2.23857625,108 3.38176876e-16,105.761424 0,103 Z" id="zona-4-4A" fill={layout ? layout.find(o => o.codigo === 'Z6').semaforo : 0}/>
            <text id="Z6-:-VACÍOS" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="19.9842773" y="23">Z6 : VACÍOS</tspan>
            </text>
            <text id="CAP:-12" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="34.4414551" y="47">CAP: {layout ? layout.find(o => o.codigo === 'Z6').capacidad : 0}</tspan>
            </text>
            <text id="CANTIDAD-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="24.1374023" y="79">CANTIDAD</tspan>
                <tspan x="58.4288574" y="96">{layout ? layout.find(o => o.codigo === 'Z6').totalContenedores : 0}</tspan>
            </text>
        </g>



        <g id="zona-cg-9" transform="translate(670.000000, 574.000000) rotate(-360.000000) translate(-670.000000, -574.000000) translate(647.000000, 498.000000)">
            <path d="M0,147 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L41,0 C43.7614237,-5.07265313e-16 46,2.23857625 46,5 L46,147 C46,149.761424 43.7614237,152 41,152 L5,152 C2.23857625,152 3.38176876e-16,149.761424 0,147 Z" id="zona-4-4A" fill={layout ? layout.find(o => o.codigo === 'CG-9').semaforo : 0}/>
            <text id="CG-9" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="5.48129883" y="22">CG-9</tspan>
            </text>
            <text id="CANT-2" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="2.56992188" y="108">CANT</tspan>
                <tspan x="18.9288574" y="125">{layout ? layout.find(o => o.codigo === 'CG-9').totalContenedores : 0}</tspan>
            </text>
            <text id="C:16" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="6.64389648" y="67">C:{layout ? layout.find(o => o.codigo === 'CG-9').capacidad : 0}</tspan>
            </text>
        </g>
        <g id="zona-cg-10" transform="translate(594.000000, 578.000000) rotate(-180.000000) translate(-594.000000, -578.000000) translate(557.000000, 498.000000)">
            <path d="M0,155 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L69,0 C71.7614237,5.96246721e-15 74,2.23857625 74,5 L74,100.151361 C74,102.912785 71.7614237,105.151361 69,105.151361 L59,105.151361 C56.2385763,105.151361 54,107.389938 54,110.151361 L54,155 C54,157.761424 51.7614237,160 49,160 L5,160 C2.23857625,160 3.38176876e-16,157.761424 0,155 Z" id="zona-4-4A" fill={layout ? layout.find(o => o.codigo === 'CG-10').semaforo : 0}/>
            <text id="CG-10" transform="translate(37.000000, 86.500000) rotate(-180.000000) translate(-37.000000, -86.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="15.4101563" y="92">CG-10</tspan>
            </text>
            <text id="CANT-2" transform="translate(36.500000, 24.000000) rotate(-180.000000) translate(-36.500000, -24.000000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="16.0699219" y="21">CANT</tspan>
                <tspan x="32.4288574" y="38">{layout ? layout.find(o => o.codigo === 'CG-10').totalContenedores : 0}</tspan>
            </text>
            <text id="C:32" transform="translate(36.500000, 59.500000) rotate(-180.000000) translate(-36.500000, -59.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="20.6438965" y="65">C:{layout ? layout.find(o => o.codigo === 'CG-10').capacidad : 0}</tspan>
            </text>
        </g>
        {/* <g id="zona-transito2" transform="translate(577.500000, 407.000000) rotate(-180.000000) translate(-577.500000, -407.000000) translate(522.000000, 319.000000)">
            <path d="M9.09494702e-13,171 L9.09494702e-13,5 C9.09156525e-13,2.23857625 2.23857625,5.07265313e-16 5,0 L106,0 C108.761424,-1.39544373e-15 111,2.23857625 111,5 L111,90.1513613 C111,92.912785 108.761424,95.1513613 106,95.1513613 L91,95.1513613 C88.2385763,95.1513613 86,97.3899375 86,100.151361 L86,171 C86,173.761424 83.7614237,176 81,176 L5,176 C2.23857625,176 9.09832879e-13,173.761424 9.09494702e-13,171 Z" id="zona-4-4A" fill={layout ? layout.find(o => o.codigo === 'TRANSITO-2').semaforo : 0}/>
            <text id="TRANSITO-2" transform="translate(55.500000, 82.500000) rotate(-180.000000) translate(-55.500000, -82.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="12.0493164" y="88">TRANSITO 2</tspan>
            </text>
            <text id="CANTIDAD-1" transform="translate(55.500000, 28.000000) rotate(-180.000000) translate(-55.500000, -28.000000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="17.1374023" y="25">CANTIDAD</tspan>
                <tspan x="51.4288574" y="42">{layout ? layout.find(o => o.codigo === 'TRANSITO-2').totalContenedores : 0}</tspan>
            </text>
            <text id="CAP:-4" transform="translate(55.500000, 61.500000) rotate(-180.000000) translate(-55.500000, -61.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="31.5125977" y="67">CAP: {layout ? layout.find(o => o.codigo === 'TRANSITO-2').capacidad : 0}</tspan>
            </text>
        </g> */}


        <g id="zona-merma2" transform="translate(666.000000, 380.500000) rotate(-180.000000) translate(-666.000000, -380.500000) translate(639.000000, 319.000000)">
            <path d="M0,118 L0,5 C-3.38176876e-16,2.23857625 2.23857625,2.27880941e-13 5,2.27373675e-13 L49,2.27373675e-13 C51.7614237,2.2686641e-13 54,2.23857625 54,5 L54,118 C54,120.761424 51.7614237,123 49,123 L5,123 C2.23857625,123 3.38176876e-16,120.761424 0,118 Z" id="zona-4-4A" fill={layout ? layout.find(o => o.codigo === 'M2').semaforo : 0}/>
            <text id="M2" transform="translate(26.500000, 105.500000) rotate(-180.000000) translate(-26.500000, -105.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="16.2812988" y="111">M2</tspan>
            </text>
            <text id="CANT.-1" transform="translate(26.500000, 32.000000) rotate(-180.000000) translate(-26.500000, -32.000000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="4.91748047" y="29">CANT.</tspan>
                <tspan x="22.4288574" y="46">{layout ? layout.find(o => o.codigo === 'M2').totalContenedores : 0}</tspan>
            </text>
            <text id="CAP:-4" transform="translate(26.500000, 88.500000) rotate(-180.000000) translate(-26.500000, -88.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="2.51259766" y="94">CAP: {layout ? layout.find(o => o.codigo === 'M2').capacidad : 0}</tspan>
            </text>
        </g>



        <g id="zona-transito2" transform="translate(574.250000, 381.500000) rotate(-180.000000) translate(-574.250000, -381.500000) translate(545.500000, 319.000000)">
            <path d="M0,120 L0,5 C-3.38176876e-16,2.23857625 2.23857625,2.27880941e-13 5,2.27373675e-13 L52.5,2.27373675e-13 C55.2614237,2.31771684e-13 57.5,2.23857625 57.5,5 L57.5,120 C57.5,122.761424 55.2614237,125 52.5,125 L5,125 C2.23857625,125 7.69608782e-15,122.761424 0,120 Z" id="zona-4-4A" fill={layout ? layout.find(o => o.codigo === 'TRANSITO-2').semaforo : 0}/>
            <text id="T2" transform="translate(28.500000, 107.500000) rotate(-180.000000) translate(-28.500000, -107.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="19.9475586" y="113">T2</tspan>
            </text>
            <text id="CANT.-1" transform="translate(28.500000, 34.000000) rotate(-180.000000) translate(-28.500000, -34.000000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="6.91748047" y="31">CANT.</tspan>
                <tspan x="24.4288574" y="48">{layout ? layout.find(o => o.codigo === 'TRANSITO-2').totalContenedores : 0}</tspan>
            </text>
            <text id="CAP:-4" transform="translate(28.500000, 90.500000) rotate(-180.000000) translate(-28.500000, -90.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="4.51259766" y="96">CAP: {layout ? layout.find(o => o.codigo === 'TRANSITO-2').capacidad : 0}</tspan>
            </text>
        </g>

        <g id="zona-lavado" transform="translate(183.000000, 548.000000)">
            <path d="M0,109 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L75,0 C77.7614237,-5.07265313e-16 80,2.23857625 80,5 L80,109 C80,111.761424 77.7614237,114 75,114 L5,114 C2.23857625,114 3.38176876e-16,111.761424 0,109 Z" id="zona-9-9C" fill="#D0D0D0"/>
            <text id="ZONA-LAVADO" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="19.1524414" y="54">ZONA</tspan>
                <tspan x="10.0470215" y="71">LAVADO</tspan>
            </text>
        </g>
        <g id="zona-mermas" transform="translate(299.500000, 605.000000) rotate(-360.000000) translate(-299.500000, -605.000000) translate(266.000000, 548.000000)">
            <path d="M0,109 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L62,0 C64.7614237,-5.41253928e-15 67,2.23857625 67,5 L67,109 C67,111.761424 64.7614237,114 62,114 L5,114 C2.23857625,114 3.38176876e-16,111.761424 0,109 Z" id="zona-9-9C" fill="#D0D0D0"/>
            <text id="ZONA-MERMAS" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="12.9307617" y="57">ZONA </tspan>
                <tspan x="0.767480469" y="74">MERMAS</tspan>
            </text>
        </g>
        <g id="zona-z7" transform="translate(445.500000, 605.000000) rotate(-360.000000) translate(-445.500000, -605.000000) translate(338.000000, 548.000000)">
            <path d="M0,109 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L210,0 C212.761424,-5.07265313e-16 215,2.23857625 215,5 L215,109 C215,111.761424 212.761424,114 210,114 L5,114 C2.23857625,114 7.69608782e-15,111.761424 0,109 Z" id="zona-9-9A" fill={layout ? layout.find(o => o.codigo === 'Z7').semaforo : 0}/>
            <text id="Z7-:-VACÍOS" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="64.9842773" y="18">Z7 : VACÍOS</tspan>
            </text>
            <text id="CAPACIDAD-:-48" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="51.9493164" y="43">CAPACIDAD : {layout ? layout.find(o => o.codigo === 'Z7').capacidad : 0}</tspan>
            </text>
            <text id="CANTIDAD-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="61.2012207" y="68">CANTIDAD : {layout ? layout.find(o => o.codigo === 'Z7').totalContenedores : 0}</tspan>
            </text>
        </g>
        <g id="zona-z8" transform="translate(183.000000, 697.000000)">
            <path d="M0,109 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L350,0 C352.761424,-5.07265313e-16 355,2.23857625 355,5 L355,109 C355,111.761424 352.761424,114 350,114 L5,114 C2.23857625,114 3.38176876e-16,111.761424 0,109 Z" id="zona-10-10A" fill={layout ? layout.find(o => o.codigo === 'Z8').semaforo : 0}/>
            <text id="Z8-:-VACÍOS" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="134.984277" y="29">Z8 : VACÍOS</tspan>
            </text>
            <text id="CAPACIDAD:-60" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="121.433057" y="54">CAPACIDAD: {layout ? layout.find(o => o.codigo === 'Z8').capacidad : 0}</tspan>
            </text>
            <text id="CANTIDAD:-60" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="126.613818" y="79">CANTIDAD: {layout ? layout.find(o => o.codigo === 'Z8').totalContenedores : 0}</tspan>
            </text>
        </g>
        
        
        <g id="zona-acceso-camion" transform="translate(34.000000, 72.000000)" fill="#00315E" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2">
            <text id="ACCESO-CAMIÓN">
                <tspan x="12.5125977" y="14">ACCESO</tspan>
                <tspan x="14.1861816" y="31">CAMIÓN</tspan>
            </text>
        </g>
        <rect id="Rectangle" fill="#FFF1A8" transform="translate(437.000000, 860.000000) rotate(-180.000000) translate(-437.000000, -860.000000) " x="21" y="838" width="832" height="44" rx="9"/>
        <text id="L-=-%-DE-CONTENEDORE" transform="translate(437.000000, 859.500000) rotate(-360.000000) translate(-437.000000, -859.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
            <tspan x="48.4828125" y="865">{leyenda}</tspan>
        </text>
    </g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }
  return (
    <>
      {logo}
    </>
  );
});

PlantaPhilippiv2.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default PlantaPhilippiv2;