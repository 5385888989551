import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';
// ----------------------------------------------------------------------

const CHART_HEIGHT = 350;
const LEGEND_HEIGHT = 90;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(0),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
  total: PropTypes.number,
  muestraUnidades: PropTypes.bool
};

export default function AppCurrentVisits({ title, subheader, chartColors, chartData, total,muestraUnidades,  ...other }) {
  const theme = useTheme();

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = useChart({
    
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { 
      floating: true, 
      horizontalAlign: 'center', 
      fontSize: '18px', 
      markers:{
        width:15,
        height:15
      }
    },
    dataLabels: { 
      enabled: true, 
      "formatter": function (val, opts) {
        if( muestraUnidades ) {
          return Math.trunc(parseInt(val * total /100, 10))
        } 
        return ( String(Math.trunc(parseInt(val, 10))) + String('%') )
        
    },
      dropShadow: { 
        enabled: true 
      }, 
      style: {
        fontSize: "20px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold"
      },
      
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
      style: {
        fontSize: "20px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold"
      }
    },
    plotOptions: {
      pie: { donut: { labels: { show: true, 
        value: {
          show: true,
          color: undefined,
          offsetY: 16,
          "formatter": function (val) {
            return Math.trunc(parseInt(val, 10))
          }
        },
        total: {
          show: true,
          "formatter": function (w) {
            return w.globals.seriesTotals.reduce((a, b) => {
              return Math.trunc(a + b)
            }, 0)
          }
        }
      } } },
    },

  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} titleTypographyProps={{variant:'h5', textAlign:'center'}}/>

      <StyledChartWrapper dir="ltr">
        <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={280} />
      </StyledChartWrapper>
    </Card>
  );
}
