
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

const PlantaPhilippi2 = forwardRef(({ disabledLink = false, leyenda = '', layout = [], sx, ...other }, ref) => {

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 900,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
<svg width="100%" height="100%" viewBox="0 0 874 900">
    <title>SegundoPiso</title>
    <g id="SegundoPiso" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" fill="#FFF1A8" x="26" y="834" width="832" height="44" rx="9"/>
        <text id="L-=-%-DE-CONTENEDORE" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
            <tspan x="53.4828125" y="862">L = % DE CONTENEDORES LLENOS / C = CAPACIDAD MÁX. DE LA ZONA / V = % DE CONTENEDORES VACIOS </tspan>
        </text>
        <polygon id="planta" fill="#FFFEF7" transform="translate(448.875000, 420.500000) rotate(-180.000000) translate(-448.875000, -420.500000) " points="46.5 96 143.25 71.5 244.25 66.5 244.25 56.5 356.25 56.5 356.25 33.5 851.25 33.5 851.25 807.5 48.25 807.5"/>
        <polygon id="Path-3" fill="#ECEBE3" points="49.5 520 154.146147 520.008051 154.146147 267.5 175 267.5 175 220 90 220 90 350 49.5 350"/>
        <polygon id="Path-4" fill="#ECEBE3" points="49.5 132.267146 175 132.267146 175 34 49.5 34"/>
        <g id="paredes" transform="translate(439.500000, 422.000000) rotate(-180.000000) translate(-439.500000, -422.000000) translate(23.000000, 33.000000)" stroke="#FFF1A8" strokeLinecap="square" strokeWidth="6">
            <line x1="1.5" y1="118.5" x2="74.5" y2="118.5" id="Line-14"/>
            <line x1="98.5" y1="38.5" x2="98.5" y2="403.5" id="Line-15"/>
            <line x1="98.5" y1="118.5" x2="288.5" y2="118.5" id="Line-16"/>
            <line x1="0" y1="61" x2="3.5" y2="774.5" id="Line-6"/>
            <line x1="0.5" y1="61.5" x2="98.5" y2="38.5" id="Line"/>
            <line x1="98.5" y1="38.5" x2="199.5" y2="33.5" id="Line-2"/>
            <line x1="200" y1="69" x2="199.5" y2="23.5" id="Line-3"/>
            <line x1="311.5" y1="23.5" x2="311.5" y2="118.5" id="Line-17"/>
            <line x1="199.5" y1="23.5" x2="311.5" y2="23.5" id="Line-4"/>
            <line x1="311.5" y1="0.5" x2="806.5" y2="0.5" id="Line-5"/>
            <line x1="806.5" y1="0.5" x2="806.5" y2="267.5" id="Line-7"/>
            <line x1="806.5" y1="288.5" x2="806.5" y2="324.5" id="Line-8"/>
            <line x1="806.5" y1="387.5" x2="806.5" y2="443.5" id="Line-9"/>
            <line x1="806.5" y1="520.5" x2="806.5" y2="592.5" id="Line-10"/>
            <line x1="806.5" y1="592.5" x2="832.5" y2="592.5" id="Line-11"/>
            <line x1="806.5" y1="613.5" x2="806.5" y2="774.5" id="Line-12"/>
            <line x1="809.5" y1="777.5" x2="6.5" y2="777.5" id="Line-13"/>
        </g>
        <g id="zona-23" transform="translate(387.500000, 371.500000) rotate(-360.000000) translate(-387.500000, -371.500000) translate(243.000000, 345.000000)">
            <path d="M0,48 L0,5 C-1.2263553e-15,2.23857625 2.23857625,-3.80913106e-16 5,0 L284,0 C286.761424,3.0488837e-14 289,2.23857625 289,5 L289,48 C289,50.7614237 286.761424,53 284,53 L5,53 C2.23857625,53 3.12895393e-15,50.7614237 0,48 Z" id="zona-Proveedores" fill={layout ? layout.find(o => o.codigo === 'Z23').semaforo : 0}/>
            <text id="Z23" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="5" y="19">Z23</tspan>
            </text>
            <text id="CAPACIDAD-:-28" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="5" y="39">CAPACIDAD : {layout ? layout.find(o => o.codigo === 'Z23').capacidad : 0}</tspan>
            </text>
            <text id="CANTIDAD-:-28" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="178.260156" y="22">CANTIDAD : {layout ? layout.find(o => o.codigo === 'Z23').totalContenedores : 0}</tspan>
            </text>
            <text id="%-OCUPACIÓN-:-28" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="149.123828" y="39">% OCUPACIÓN : 0</tspan>
            </text>
        </g>
        <g id="zona-21" transform="translate(372.500000, 218.500000) rotate(-360.000000) translate(-372.500000, -218.500000) translate(239.000000, 171.000000)">
            <path d="M0,90 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L262,0 C264.761424,-5.07265313e-16 267,2.23857625 267,5 L267,90 C267,92.7614237 264.761424,95 262,95 L5,95 C2.23857625,95 3.38176876e-16,92.7614237 0,90 Z" id="zona-Proveedores" fill={layout ? layout.find(o => o.codigo === 'Z21').semaforo : 0}/>
            <text id="Z21" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="11" y="22">Z21 </tspan>
            </text>
            <text id="CAPACIDAD-:-32" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="11" y="42">CAPACIDAD : {layout ? layout.find(o => o.codigo === 'Z21').capacidad : 0}</tspan>
            </text>
            <text id="CANTIDAD-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="11" y="68">CANTIDAD : {layout ? layout.find(o => o.codigo === 'Z21').totalContenedores : 0}</tspan>
            </text>
            <text id="%-OCUPACIÓN-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="11" y="88">% OCUPACIÓN : 0</tspan>
            </text>
        </g>
        <g id="zona-22" transform="translate(710.500000, 299.500000) rotate(-360.000000) translate(-710.500000, -299.500000) translate(577.000000, 252.000000)">
            <path d="M0,90 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L262,0 C264.761424,-5.07265313e-16 267,2.23857625 267,5 L267,90 C267,92.7614237 264.761424,95 262,95 L5,95 C2.23857625,95 3.38176876e-16,92.7614237 0,90 Z" id="zona-Proveedores" fill={layout ? layout.find(o => o.codigo === 'Z22').semaforo : 0}/>
            <text id="Z22" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="19" y="21">Z22</tspan>
            </text>
            <text id="CAPACIDAD-:-24" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="19" y="41">CAPACIDAD : {layout ? layout.find(o => o.codigo === 'Z22').capacidad : 0}</tspan>
            </text>
            <text id="CANTIDAD-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="19" y="70">CANTIDAD : {layout ? layout.find(o => o.codigo === 'Z22').totalContenedores : 0}</tspan>
            </text>
            <text id="%-OCUPACIÓN-:-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="19" y="87">% OCUPACIÓN : 0</tspan>
            </text>
        </g>
        <g id="zona-20" transform="translate(511.500000, 128.500000) rotate(-180.000000) translate(-511.500000, -128.500000) translate(179.000000, 49.000000)">
            <path d="M0,154 L0,12 C-8.11624501e-16,5.372583 5.372583,-5.58920087e-16 12,0 L258.639257,0 C265.266674,2.42909873e-13 270.639257,5.372583 270.639257,12 L270.639257,70 L270.639257,70 L660,70 C662.761424,70 665,72.2385763 665,75 L665,154 C665,156.761424 662.761424,159 660,159 L5,159 C2.23857625,159 3.38176876e-16,156.761424 0,154 Z" id="zona-Proveedores" fill={layout ? layout.find(o => o.codigo === 'Z20').semaforo : 0}/>
            <text id="Z20-:-LLENOS" transform="translate(127.000000, 119.000000) rotate(-180.000000) translate(-127.000000, -119.000000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="10" y="116">Z20 : LLENOS</tspan>
            </text>
            <text id="CANTIDAD-:-1" transform="translate(127.000000, 51.500000) rotate(-180.000000) translate(-127.000000, -51.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="10" y="57">CANTIDAD : {layout ? layout.find(o => o.codigo === 'Z20').totalContenedores : 0}</tspan>
            </text>
            <text id="%-OCUPACIÓN-:-1" transform="translate(127.000000, 28.500000) rotate(-180.000000) translate(-127.000000, -28.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="10" y="34">% OCUPACIÓN : 0</tspan>
            </text>
            <text id="CAPACIDAD-:-81" transform="translate(185.500000, 107.500000) rotate(-180.000000) translate(-185.500000, -107.500000) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="127" y="113">CAPACIDAD : {layout ? layout.find(o => o.codigo === 'Z20').capacidad : 0}</tspan>
            </text>
        </g>
        <g id="zona-27" transform="translate(833.413565, 520.000000) rotate(-360.000000) translate(-833.413565, -520.000000) translate(815.827129, 406.000000)">
            <path d="M0,223 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L30,0 C32.7614237,-5.07265313e-16 35,2.23857625 35,5 L35,223 C35,225.761424 32.7614237,228 30,228 L5,228 C2.23857625,228 3.38176876e-16,225.761424 0,223 Z" id="zona-Proveedores" fill={layout ? layout.find(o => o.codigo === 'Z27').semaforo : 0}/>
            <text id="Z27" transform="translate(17.672871, 26.027471) rotate(-360.000000) translate(-17.672871, -26.027471) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="5.04928692" y="31.5274708">Z27</tspan>
            </text>
            <text id="CAP-14" transform="translate(17.672871, 81.527471) rotate(-360.000000) translate(-17.672871, -81.527471) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="2.13790997" y="78.5274708">CAP</tspan>
                <tspan x="9.53058575" y="95.5274708">{layout ? layout.find(o => o.codigo === 'Z27').capacidad : 0}</tspan>
            </text>
            <text id="CAN1" transform="translate(17.672871, 140.527471) rotate(-360.000000) translate(-17.672871, -140.527471) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="1.72409161" y="137.527471">CAN</tspan>
                <tspan x="13.6017283" y="154.527471">{layout ? layout.find(o => o.codigo === 'Z27').totalContenedores : 0}</tspan>
            </text>
            <text id="%O-1" transform="translate(17.672871, 196.527471) rotate(-360.000000) translate(-17.672871, -196.527471) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="5.3704295" y="193.527471">%O</tspan>
                <tspan x="13.6017283" y="210.527471">0</tspan>
            </text>
        </g>
        <g id="zona-26" transform="translate(778.337625, 462.500000) rotate(-360.000000) translate(-778.337625, -462.500000) translate(760.675251, 405.000000)">
            <path d="M0,110 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L30,0 C32.7614237,-5.07265313e-16 35,2.23857625 35,5 L35,110 C35,112.761424 32.7614237,115 30,115 L5,115 C2.23857625,115 3.38176876e-16,112.761424 0,110 Z" id="zona-Proveedores" fill={layout ? layout.find(o => o.codigo === 'Z26').semaforo : 0}/>
            <text id="Z26" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="5.20116519" y="26.0136057">Z26</tspan>
            </text>
            <text id="CAP-26" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="2.28978823" y="48.0136057">CAP</tspan>
                <tspan x="9.68246401" y="65.0136057">{layout ? layout.find(o => o.codigo === 'Z26').capacidad : 0}</tspan>
            </text>
            <text id="CAN-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="1.87596987" y="89.0136057">CAN</tspan>
                <tspan x="13.7536066" y="106.013606">{layout ? layout.find(o => o.codigo === 'Z26').totalContenedores : 0}</tspan>
            </text>
        </g>
        <g id="zona-25" transform="translate(715.567867, 516.000000) rotate(-360.000000) translate(-715.567867, -516.000000) translate(688.000000, 402.000000)">
            <path d="M0.135734313,223 L0.135734313,5 C0.135734313,2.23857625 2.37431056,5.07265313e-16 5.13573431,0 L50.1357343,0 C52.8971581,-5.07265313e-16 55.1357343,2.23857625 55.1357343,5 L55.1357343,223 C55.1357343,225.761424 52.8971581,228 50.1357343,228 L5.13573431,228 C2.37431056,228 0.135734313,225.761424 0.135734313,223 Z" id="zona-Proveedores" fill={layout ? layout.find(o => o.codigo === 'Z25').semaforo : 0}/>
            <text id="Z25" transform="translate(27.500000, 26.008051) rotate(-360.000000) translate(-27.500000, -26.008051) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="14.876416" y="31.5080514">Z25</tspan>
            </text>
            <text id="CAP-22" transform="translate(27.500000, 67.008051) rotate(-360.000000) translate(-27.500000, -67.008051) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="11.9650391" y="62.5080514">CAP</tspan>
                <tspan x="19.3577148" y="79.5080514">{layout ? layout.find(o => o.codigo === 'Z25').capacidad : 0}</tspan>
            </text>
            <text id="CANT-22" transform="translate(27.500000, 118.008051) rotate(-360.000000) translate(-27.500000, -118.008051) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="7.06992188" y="113.508051">CANT</tspan>
                <tspan x="19.3577148" y="130.508051">{layout ? layout.find(o => o.codigo === 'Z25').totalContenedores : 0}</tspan>
            </text>
            <text id="%-OCU-1" transform="translate(27.500000, 172.008051) rotate(-360.000000) translate(-27.500000, -172.008051) " fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="2.58129883" y="167.508051">% OCU</tspan>
                <tspan x="23.4288574" y="184.508051">0</tspan>
            </text>
        </g>
        <g id="zona-24" transform="translate(572.499544, 470.500000) rotate(-360.000000) translate(-572.499544, -470.500000) translate(544.999087, 403.000000)">
            <path d="M0,130 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 L50,0 C52.7614237,-5.07265313e-16 55,2.23857625 55,5 L55,130 C55,132.761424 52.7614237,135 50,135 L5,135 C2.23857625,135 3.38176876e-16,132.761424 0,130 Z" id="zona-Proveedores" fill={layout ? layout.find(o => o.codigo === 'Z24').semaforo : 0}/>
            <text id="Z24" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="14.8773286" y="23.099688">Z24</tspan>
            </text>
            <text id="CANT-24" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="7.07083446" y="55.099688">CANT</tspan>
                <tspan x="19.3586274" y="72.099688">{layout ? layout.find(o => o.codigo === 'Z24').totalContenedores : 0}</tspan>
            </text>
            <text id="%-OCU-1" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
                <tspan x="2.58221142" y="104.099688">% OCU</tspan>
                <tspan x="23.42977" y="121.099688">0</tspan>
            </text>
        </g>
        <rect id="Rectangle" fill="#FFF1A8" transform="translate(82.500000, 684.500000) rotate(-180.000000) translate(-82.500000, -684.500000) " x="65" y="630" width="35" height="109"/>
        <rect id="Rectangle" fill="#FFF1A8" transform="translate(72.500000, 286.000000) rotate(-180.000000) translate(-72.500000, -286.000000) " x="55" y="221" width="35" height="130"/>
        <rect id="Rectangle" fill="#FFF1A8" transform="translate(531.500000, 228.500000) rotate(-180.000000) translate(-531.500000, -228.500000) " x="514" y="166" width="35" height="125"/>
        <rect id="Rectangle" fill="#FFF1A8" transform="translate(276.000000, 535.500000) rotate(-180.000000) translate(-276.000000, -535.500000) " x="218" y="520" width="116" height="31"/>
        <rect id="Rectangle" fill="#FFF1A8" transform="translate(538.500000, 561.500000) rotate(-180.000000) translate(-538.500000, -561.500000) " x="532" y="546" width="13" height="31"/>
        <rect id="Rectangle" fill="#D0D0D0" transform="translate(194.500000, 254.500000) rotate(-180.000000) translate(-194.500000, -254.500000) " x="160" y="226" width="69" height="57" rx="5"/>
        <text id="MONTA-CARGA" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
            <tspan x="168.061523" y="252">MONTA</tspan>
            <tspan x="167.501221" y="269">CARGA</tspan>
        </text>
        <rect id="Rectangle" fill="#D0D0D0" transform="translate(130.000000, 188.000000) rotate(-180.000000) translate(-130.000000, -188.000000) " x="85" y="155" width="90" height="66" rx="5"/>
        <text id="BAÑO" fontFamily="Arial-BoldMT, Arial" fontSize="15" fontWeight="bold" letterSpacing="-0.2" fill="#00315E">
            <tspan x="108.31748" y="195.894737">BAÑO</tspan>
        </text>
    </g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }
  return (
    <>
      {logo}
    </>
  );
});

PlantaPhilippi2.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default PlantaPhilippi2;