import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme, styled} from '@mui/material/styles';
import { Grid, Container, Typography, Box} from '@mui/material';
import moment from 'moment';
// redux
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLayout } from '../store/slices/indicadores';

// components
import {Logo, LogoPolarycs} from '../components/logo'
import PlantaPhilippiv2 from '../components/layoutsPhilippi/Planta1v2';
import PlantaPhilippi2 from '../components/layoutsPhilippi/Planta2v2';
// ----------------------------------------------------------------------

export default function LayoutPhilippi() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const [ time, setTime ] = useState(0);
  const [counter, setCounter] = useState(1);

  const { loadingLayout, layout } = useSelector((state) => state.indicadores);

  useEffect(() => {
    dispatch(getLayout());
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCounter((prevCounter) => prevCounter + 1);
  //     window.location.reload(false);
  //   }, 60000);
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  const BoxLayout = styled('div')(({ theme }) => ({
    margin: 'auto',
    borderRadius: '5px',
    alignItems: 'center',
    width:'48%',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    backgroundColor: '#e8f4fc',
  }));
  
  if(!loadingLayout) {
    return (
      <>
        <Helmet>
          <title>Bodega EcoCarga</title>
        </Helmet>

        <Container style={{maxWidth:'100%'}}>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', right: 50 }}>
            <Logo />
          </Box>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', bottom: 20, right:50 }}>
            <LogoPolarycs sx={{marginLeft:2}}/>
          </Box>
          {/* <Typography variant="h3" sx={{ mb: 2 }} mt={-13}>
            Mercado Circular / EcoCarga / Layout Philippi / Actualizado {moment().format('HH:mm:ss')}
          </Typography> */}


          <Grid container spacing={3} mt={-10}>
            <BoxLayout item xs={12} sm={6} md={6} sx={{textAlign:'center'}}>
              <Typography variant="h4" mt={5} mb={-5} ml={5} sx={{textAlign:'left'}}>
                1er Piso
              </Typography>
              <PlantaPhilippiv2 mb={2} leyenda="C = Capacidad Zona / L = % IBC LLenos / V = % IBC Vacios" layout={layout}/>
            </BoxLayout>
            <BoxLayout item xs={12} sm={6} md={6} sx={{textAlign:'center'}}>
              <Typography variant="h4" mt={5} mb={-5} ml={5} sx={{textAlign:'left'}}>
                2do Piso
              </Typography>
              <PlantaPhilippi2 mb={2} leyenda="VERDE = Zona Nivel Normal / AMARILLO = Zona Nivel Medio / ROJO = Zona completa" layout={layout}/>
            </BoxLayout>
          </Grid>
        </Container>
      </>
    );
  }

  return (<>
  
    <Container style={{maxWidth:'100%'}}>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Logo />
          </Box>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Typography variant="h3" sx={{ mb: 2 }} mt={2}>
              Refrescando Planta...
            </Typography>
          </Box>
    </Container>
  
  </>)
}
