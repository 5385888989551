import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box} from '@mui/material';
import moment from 'moment';
// redux
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDisponibilidadEcoVending, setIndicadores } from '../store/slices/indicadores';

// components
import {Logo, LogoPolarycs} from '../components/logo'
// sections
import {
  AppCurrentVisits,
  AppWidgetSummary,
  AppKeep
} from '../sections/@dashboard/app';
import Label from '../components/label/Label';


// ----------------------------------------------------------------------

export default function EcoVendings() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const [ time, setTime ] = useState(0);
  const [counter, setCounter] = useState(1);

  const { loadingIndicadores, disponibilidadEcoVendings, kpi } = useSelector((state) => state.indicadores);

  useEffect(() => {
    dispatch(getDisponibilidadEcoVending());
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCounter((prevCounter) => prevCounter + 1);
  //     window.location.reload(false);
  //   }, 60000);
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  if(!loadingIndicadores) {
    return (
      <>
        <Helmet>
          <title>EcoVendings EcoCarga</title>
        </Helmet>

        <Container style={{maxWidth:'100%'}}>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', right: 50 }}>
            <Logo />
          </Box>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', bottom: 20, right:50 }}>
            <LogoPolarycs sx={{marginLeft:2}}/>
          </Box>
          <Typography variant="h3" sx={{ mb: 2 }} mt={-13}>
            Mercado Circular / EcoCarga / KPI EcoVendings / Actualizado {moment().format('HH:mm:ss')}
          </Typography>


 
          <Grid container spacing={3} sx={{ mb: 2 }}>
            
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="POS en Operación" total={kpi.kpiOperacionPOS} color="ecocargaDetergente" icon={'ant-design:android-filled'} showTitle/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Maq. 3 en 1" total={kpi.kpiMaquinasMultiproducto} color="ecocargaDetergente" icon={'ant-design:android-filled'} showTitle/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="KA Ok" total={0} color="ecocargaDetergente" icon={'ant-design:apple-filled'} showTitle/>
            </Grid>
            
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Sin Ventas" total={kpi.kpiSinVentas} color={(kpi.kpiSinVentas > 0) ? 'errorTag': 'okTag'} icon={'ant-design:windows-filled'} showTitle/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Sobre TPV" total={0} color="ecocargaDetergente" icon={'ant-design:windows-filled'} showTitle/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Bajo TPV" total={0}color="ecocargaDetergente" icon={'ant-design:windows-filled'} showTitle/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Alertas Comunicación" total={kpi.kpiAlertasComunicacion} color={(kpi.kpiAlertasComunicacion > 0) ? 'errorTag': 'okTag'} icon={'ant-design:windows-filled'} showTitle/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Alertas Nivel" total={kpi.kpiAlertasNivel} color={(kpi.kpiAlertasNivel > 0) ? 'errorTag': 'okTag'} icon={'ant-design:windows-filled'} showTitle/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Bajo Prom. Eventos" total={0} color="ecocargaDetergente" icon={'ant-design:windows-filled'} showTitle/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Desconectadas" total={kpi.kpiDesconectadas} color={(kpi.kpiDesconectadas > 0) ? 'errorTag': 'okTag'} icon={'ant-design:windows-filled'} showTitle/>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary title="Bajo Prom. Ventas" total={0} color="ecocargaDetergente" icon={'ant-design:windows-filled'} showTitle/>
            </Grid>
            
          </Grid>


          <Grid container spacing={3} sx={{ mb: 2}}>
            <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6" align="left" >
                EcoVending
              </Typography>
              </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <Typography variant="h6" align="center" >
                Dirección
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h6" align="center">
                Tag
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h6" align="left">
                Estados
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <Typography variant="h6" align="center">
                Multiproducto
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <Typography variant="h6" align="center">
                KA [Un]
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <Typography variant="h6" align="center">
                KA [%]
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <Typography variant="h6" align="center">
                DS [Un]
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
              <Typography variant="h6" align="center">
                TPV [Un]
              </Typography>
            </Grid>
          </Grid> 


          {disponibilidadEcoVendings.map(ecovending => (
            <>
            <Grid container spacing={3} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6} md={2}>
                <Typography variant="h6">
                 [{ecovending.codEcoCarga}] {ecovending.nombre}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
              {ecovending.direccion}
              </Grid>
              
              <Grid item xs={12} sm={6} md={2} align="center" >
                <Label color={ecovending.tag.color}>{ecovending.tag.tipo}</Label>
              </Grid>

              <Grid item xs={12} sm={6} md={2} align="center" >
                
                <div className="listaHorizontal">
                  <div className="itemLista">
                    <Label color={ecovending.conectada ? 'online' : 'offline'}>
                      {ecovending.conectada ? 'Online' : 'Offline'}
                    </Label>
                  </div>
                  {/* notif nivel de estanque */}
                  <div className="itemLista">
                    <Label color={(ecovending.notificaciones.falla_nivel.existe) ? 'errorTag' : 'okTag'} >
                      N.E. {ecovending.notificaciones.falla_nivel.contador} 
                    </Label>
                  </div>
                  {/* perdida de conexion */}
                  <div className="itemLista">
                    <Label color={(ecovending.notificaciones.perdida_conexion.existe) ? 'errorTag' : 'okTag'} >
                      P.C. {ecovending.notificaciones.perdida_conexion.contador} 
                    </Label>
                  </div>
                  {/* falla precarga */}
                  <div className="itemLista">
                    <Label color={(ecovending.notificaciones.falla_precarga.existe) ? 'errorTag' : 'okTag'} >
                      F.P. {ecovending.notificaciones.falla_precarga.contador} 
                    </Label>
                  </div>
                  {/* error impresion */}
                  <div className="itemLista">
                    <Label color={(ecovending.notificaciones.error_impresion.existe) ? 'errorTag' : 'okTag'} >
                      E.I. {ecovending.notificaciones.error_impresion.contador} 
                    </Label>
                  </div>
                </div>



              </Grid>
              
              <Grid item xs={12} sm={6} md={1}  align="center">
              {(ecovending.multiProducto) ? "Si" : "No"}
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
                <AppKeep title="Keep-Alive" total={ecovending.ventasHoy}  color={(ecovending.ventasHoy === 0) ? 'errorTag' : 'okTag'} icon={'ant-design:windows-filled'} />
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
                <AppKeep title="% Keep-Alive" total="3" color="ecocargaClorogel" icon={'ant-design:windows-filled'}  />
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
                <AppKeep title="% Keep-Alive" total="3" color="ecocargaClorogel" icon={'ant-design:windows-filled'}  />
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
                <AppKeep title="% Keep-Alive" total="3" color="ecocargaClorogel" icon={'ant-design:windows-filled'}  />
              </Grid>
            </Grid>
            </>
          ))}

          

        </Container>
      </>
    );
  }

  return (<>
  
    <Container style={{maxWidth:'100%'}}>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Logo />
          </Box>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Typography variant="h3" sx={{ mb: 2 }} mt={2}>
              Refrescando Indicadores...
            </Typography>
          </Box>
    </Container>
  
  </>)
}
