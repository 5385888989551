import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme, styled} from '@mui/material/styles';
import { Grid, Container, Typography, Box, Table, TableBody, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
// redux
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLayout } from '../store/slices/indicadores';

// components
import {Logo, LogoPolarycs} from '../components/logo'
// ----------------------------------------------------------------------

export default function Picking() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const [ time, setTime ] = useState(0);
  const [counter, setCounter] = useState(1);

  const { loadingCalendario, calendario } = useSelector((state) => state.indicadores);

  // useEffect(() => {
  //   dispatch(getLayout());
  // }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
      window.location.reload(false);
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const TagDespacho = styled('div')(() => ({
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ecocargaDetergente.lighter,
    paddingTop: 5, 
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight:15,
    marginRight: 15,
    display: 'inline-block'
  }));

  const TagDespachoCantidad = styled('div')(({ theme }) => ({
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ecocargaLavalozas.lighter,
    paddingTop: 5, 
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight:15,
    marginRight: -15,
    marginTop:-10,
    marginBottom:-10,
    marginLeft:5,
    color: theme.palette.ecocargaLavalozas.darken, 
    fontWeight: 'bold',
    display: 'inline-block',
    borderTopLeftRadius:0,
    borderBottomLeftRadius:0
  }));

  const BoxLayout = styled('div')(({ theme }) => ({
    margin: 'auto',
    borderRadius: '5px',

    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    backgroundColor: '#e8f4fc',
    padding: 20
  }));

  const BoxLayoutContainer = styled('div')(({ theme }) => ({
    borderRadius: '5px',

    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    padding: 20,
    display: 'grid',
    gridTemplateColumns:'25% 25% 25% 25%',
    gridGap: 20,
    gridAutoFlow: 'column',
    textAlign: 'center',
    gridAutoRows: '100px'

  }));

  const TarjetaDespacho = styled('div')(({ theme }) => ({
    borderRadius: '5px',
    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
    padding: 10,
    boxShadow: '0px 8px 20px 0px #dbdbdb',
    border : '2px solid #646464'
  }));
  
  const BoxDespacho = styled('div')(({ theme }) => ({
    borderRadius: '0px',
    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
    padding: 20,
    borderLeft : '4px solid #646464'
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#acd8ff",
      color: "#00315e",
      fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }));

  const BotonEstado = styled('div')(({ theme }) => ({
    borderRadius: '100%',
    width:'20px',
    height: '20px',
    backgroundColor: 'red',
    marginRight: '20px',
    position: 'absolute',
    display: 'inline-flex',
    top: '5px',
    left: 0

  }));

  function getDayCL(day) {
    if (day === 1) return 'Lunes';
    if (day === 2) return 'Martes';
    if (day === 3) return 'Miércoles';
    if (day === 4) return 'Jueves';
    if (day === 5) return 'Viernes';
    if (day === 6) return 'Sábado';
    if (day === 7) return 'Domingo';
    return 'x'
  }
  
  function renderTarjeta(data) {
    return(
      <>
      <BoxDespacho>
        <Typography variant="h6" sx={{fontWeight: 'bold'}}>
          RUTA / {data.ruta}
        </Typography>
        <Typography variant="h6" sx={{fontWeight: 'bold', marginBottom:2}}>
          CAMION / {data.camion}
        </Typography>

        {(data.despacho.map(y => {
          return(
            <>
            <TarjetaDespacho>
              {(y.items.map( e => {
                return(
                  <Typography variant="h5" sx={{ mb: 1, position: 'relative', paddingLeft: '30px'}}>
                    <BotonEstado />
                    {e.cantidad} x {e.producto} 
                    
                  </Typography>
                )
              }))}
              <Typography variant="h6" sx={{fontWeight: 'normal'}}>
                {y.pos}
              </Typography>
            </TarjetaDespacho>
            </>
          )
        }))}
      </BoxDespacho>
        
      </>
    )
  }

  function renderCaja(data) {
    return(
      <>
        <BoxDespacho>
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>
            {data}
          </Typography>
        </BoxDespacho>
      </>
    )
  }


  if(!loadingCalendario) {
    return (
      <>
        <Helmet>
          <title>Bodega EcoCarga</title>
        </Helmet>

        <Container style={{maxWidth:'100%'}}>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', right: 50 }}>
            <Logo />
          </Box>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', bottom: 20, right:50 }}>
            <LogoPolarycs sx={{marginLeft:2}}/>
          </Box>
          <Typography variant="h3" sx={{ mb: 2 }} mt={-13}>
            Mercado Circular / EcoCarga / Pedidos del día / {moment().format('DD-MM-YYYY HH:mm:ss')}
          </Typography>

          <BoxLayoutContainer item xs={12} sm={6} md={6} sx={{textAlign:'left'}}>

            <BoxLayout item xs={4} sm={4} md={4} sx={{textAlign:'left', backgroundColor: '#2ecc71'}}>
              
              <Typography variant="h3" sx={{ mb: 2 }}>
                Despachados {moment().format('DD-MM-YYYY')}
              </Typography>

              {renderCaja('Sin movimientos')}

            </BoxLayout>

            <BoxLayout item xs={4} sm={4} md={4} sx={{textAlign:'left', backgroundColor:'#e74c3c'}}>
              
              <Typography variant="h3" sx={{ mb: 2 }}>
                Pendientes {moment().add(1, 'days').format('DD-MM-YYYY')}
              </Typography>

              {renderCaja('Sin movimientos')}

            </BoxLayout>

            <BoxLayout item xs={4} sm={4} md={4} sx={{textAlign:'left', backgroundColor:'#f1c40f'}}>
              
              <Typography variant="h3" sx={{ mb: 2 }}>
                Procesados {moment().add(1, 'days').format('DD-MM-YYYY')}
              </Typography>

              {renderCaja('Sin movimientos')}

            </BoxLayout>

            <BoxLayout item xs={4} sm={4} md={4} sx={{textAlign:'left', backgroundColor: '#2ecc71'}}>
              
              <Typography variant="h3" sx={{ mb: 2 }}>
                Despachados {moment().add(1, 'days').format('DD-MM-YYYY')}
              </Typography>

              {renderCaja('Sin movimientos')}

            </BoxLayout>


          </BoxLayoutContainer>
          

        </Container>
      </>
    );
  }

  return (<>
  
    <Container style={{maxWidth:'100%'}}>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Logo />
          </Box>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Typography variant="h3" sx={{ mb: 2 }} mt={2}>
              Refrescando Picking...
            </Typography>
          </Box>
    </Container>
  
  </>)
}
