import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme, styled} from '@mui/material/styles';
import { Grid, Container, Typography, Box, Table, TableBody, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
// redux
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCalendarioNuevo } from '../store/slices/indicadores';

// components
import {Logo, LogoPolarycs} from '../components/logo'
// ----------------------------------------------------------------------

export default function CalendarioNuevo() {

  const theme = useTheme();
  const dispatch = useDispatch();
  const [ time, setTime ] = useState(0);
  const [counter, setCounter] = useState(1);

  const { loadingCalendario, calendario } = useSelector((state) => state.indicadores);

  useEffect(() => {
    dispatch(getCalendarioNuevo());
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
      window.location.reload(false);
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const TagDespacho = styled('div')(() => ({
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ecocargaDetergente.lighter,
    paddingTop: 5, 
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight:15,
    marginRight: 15,
    display: 'inline-block'
  }));

  const TagDespachoCantidad = styled('div')(({ theme }) => ({
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.ecocargaLavalozas.lighter,
    paddingTop: 5, 
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight:15,
    marginRight: -15,
    marginTop:-10,
    marginBottom:-10,
    marginLeft:5,
    color: theme.palette.ecocargaLavalozas.darken, 
    fontWeight: 'bold',
    display: 'inline-block',
    borderTopLeftRadius:0,
    borderBottomLeftRadius:0
  }));

  const BoxLayout = styled('div')(({ theme }) => ({
    margin: 'auto',
    borderRadius: '5px',
    width:'100%',
    justifyContent: 'center',
    display: 'grid',
    gridAutoRows: '100px'
  }));

  const BoxLayoutContainer = styled('div')(({ theme }) => ({
    borderRadius: '5px',

    width:'100%',
    // justifyContent: 'center',
    // marginBottom: theme.spacing(2),
    // padding: 20,
    // display: 'grid',
    // gridTemplateColumns:'20% 20% 20% 20% 20%',
    // gridGap: 20,
    // gridAutoFlow: 'column',
    // textAlign: 'center',
    // gridAutoRows: '100px'

  }));

  const TarjetaDespacho = styled('div')(({ theme }) => ({
    borderRadius: '5px',
    width:'100%',
    justifyContent: 'left',
    backgroundColor: '#ffffff',
    padding: 20
  }));

  const Despachos = styled('div')(({ theme }) => ({
    borderRadius: '5px',
    width:'100%',
    justifyContent: 'center',
  }));

  const ItemDespachos = styled('div')(({ theme }) => ({
    borderRadius: 0,
    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
    padding: 10,
    borderLeft : '8px solid #646464'
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#acd8ff",
      color: "#00315e",
      fontSize: 20,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
    },
  }));

  const CalendarioSemanal = styled('div')(({ theme }) => ({
    margin: 'auto',
    borderRadius: '5px',

    width:'100%',
    justifyContent: 'center',
    backgroundColor: '#7ed6df',
    marginRight: 10,
    padding: 20
  }));


  const TablaCalendario = styled('table')(({ theme }) => ({
    borderRadius: 0,
    width:'100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
    padding: 10,
    borderCollapse: 'collapse',
    minWidth:'100%',
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow:'0px 10px 26px -10px rgba(0,0,0,0.1)'
  }));

  const TablaCalendario_thead = styled('thead')(({ theme }) => ({
    borderRadius: 0,
    width:'100%',
    justifyContent: 'center',
    backgroundColor: '#1769ff',
    padding: 10,
    color: '#fff',
  }));

  
  function renderCard(data) {
    return (
      <div>
        {/* <span><o className={`patenteCamion`}>Transporte : {data.transportePatente}</o></span> */}
        <o className={`rutaCamion ruta${data.rutaId}`}>{data.rutaNombre} / {data.transportePatente}</o>
        {(data.pos.map((item) => {
          return (
            <div className="mcdoItemDespacho">
              {(item.planificacion.map((material => {
                return(
                  <div className="lineaPlanificacion"> 
                    {/* {(material.editado) ? (<o className="tagEstado rojo">{material.usuario.iniciales}</o>) : (<></>)} */}
                    <span>
                      {material.cantidad} x {material.productoNombreLimpio.replace(' ECOCARGA', '')}
                      </span> 
                  </div>
                )
              })))}              
              <span className="pos">
                <o className="pos posNombre gris">POS</o>
                <b>{item.posNombre}</b>
              </span>
            </div>
          )
        }))}
      </div>
    )
  }

  function getDia(dia) {
    const dias = [
      'Martes', 
      'Miércoles',
      'Jueves',
      'Viernes',
      'Lunes'
    ]
    return dias[dia] + ' '
  }

  if(!loadingCalendario) {
    return (
      <>
        <Helmet>
          <title>Bodega EcoCarga</title>
        </Helmet>

        <Container style={{maxWidth:'100%'}}>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', right: 50 }}>
            <Logo />
          </Box>
          <Box sx={{ px: 1, py: 1, display: 'inline-flex', position: 'absolute', bottom: 20, right:50 }}>
            <LogoPolarycs sx={{marginLeft:2}}/>
          </Box>
          <Typography variant="h3" sx={{ mb: '-20px' }} mt={-13}>
            Mercado Circular / EcoCarga / Calendario Semanal / Actualizado {moment().format('HH:mm:ss')}
          </Typography>


          <BoxLayoutContainer item xs={6} sm={6} md={6} sx={{textAlign:'center'}}>

            <TablaCalendario className="table table--responsive polar" style={{marginTop:20}}>
              <TablaCalendario_thead>
                  <tr className="text-small" >
                    {calendario.semanaPlanificacion ? (
                      <>
                        {calendario.semanaPlanificacion.map( (item, index) => (
                          <th style={{padding:5}}>
                            {getDia(index)}
                            {item.fecha.split('T')[0].split('-')[2]}
                          </th>
                        ))}
                      </>
                    ):(<></>)}
                  </tr>
              </TablaCalendario_thead>

              <tbody>
                <tr style={{verticalAlign: 'top'}}> 
                  {calendario.semanaPlanificacion ? (

                    <>
                      {calendario.semanaPlanificacion.map( (item, index) => {
                        if( item.planificacionRutas.length === 0) {
                          return(
                            <th>
                              <span className="sin-movimientos">No existen movimientos de bodega planificados para este día.</span>
                            </th>
                          )
                        } else {
                          return(
                            <th  style={{padding: 10}}>
                              {item.planificacionRutas.map((rutas) => {
                                return(
                                  <div>
                                    {renderCard(rutas)}
                                  </div>
                                )
                              })}
                            </th>
                          )
                        }

                       
                      })}
                    </>



                  ):(<></>)}                  
                </tr>
              </tbody>
            </TablaCalendario>


          </BoxLayoutContainer>
          

        </Container>
      </>
    );
  }

  return (<>
  
    <Container style={{maxWidth:'100%'}}>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Logo />
          </Box>
          <Box sx={{display: 'block', textAlign:'center'}}>
            <Typography variant="h3" sx={{ mb: 2 }} mt={2}>
              Refrescando Calendario...
            </Typography>
          </Box>
    </Container>
  
  </>)
}
