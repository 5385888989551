import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import DashboardAppPage from './pages/Indicadores';
import LayoutPhilippi from './pages/Layout';
import Calendario from './pages/Calendario';
import EcoVendings from './pages/EcoVendings';
import Picking from './pages/Picking';
import CalendarioNuevo from './pages/CalendarioNuevo';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'layout', element: <LayoutPhilippi /> },
        { path: 'calendario', element: <Calendario /> },
        { path: 'ecovendings', element: <EcoVendings /> },
        { path: 'pickingD', element: <Picking /> },
        { path: 'calendarionuevo', element: <CalendarioNuevo /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/dashboard/app" replace />,
    },
  ]);

  return routes;
}
