// import { configureStore } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";

import indicadoresReducer from "./slices/indicadores";

export const store = configureStore({
  reducer: {
    indicadores : indicadoresReducer
  },
});
 